import * as React from "react";
import { Stack, PrimaryButton, DefaultButton } from "@fluentui/react";
import i18n from "../services/i18n";

export interface IPanelEditorButtonsProps {
  acceptLabel?: string;
  cancelLabel?: string;
  onAccept: () => void;
  onDismiss: () => void;
  canAccept: boolean;
  disabled: boolean;
}

export interface IPanelEditorButtonsState {}

export class PanelEditorButtons extends React.Component<
  IPanelEditorButtonsProps,
  IPanelEditorButtonsState
> {
  constructor(props: IPanelEditorButtonsProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { acceptLabel, canAccept, disabled } = this.props;
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        style={{
          // position: "absolute",
          bottom: 0,
          padding: "10px",
          width: "100%",
        }}
      >
        <PrimaryButton
          disabled={!canAccept || disabled}
          text={acceptLabel ?? i18n.t("global:apply")}
          style={{ width: "100%" }}
          onClick={() => this.props.onAccept()}
        />
        <DefaultButton
          disabled={disabled}
          text={this.props.cancelLabel ?? i18n.t("global:cancel")}
          style={{ width: "100%" }}
          onClick={() => this.props.onDismiss()}
        />
      </Stack>
    );
  }
}
