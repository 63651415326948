import {
  CommandBar,
  DefaultPalette,
  getTheme,
  Icon,
  IContextualMenuItem,
  IContextualMenuItemProps,
  Modal,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { IQueryFilter } from "src/models/IQueryFilter";
import { uniqueId } from "src/utils/uniqueId";
import i18n from "../../services/i18n";
import {
  GridViewMode,
  IGridColumn,
  IGridCommand,
  IGridViewOptions,
} from "./GridView.types";
import { IRequestReference } from "src/models/Requests/IRequestReference";


export interface IGridViewCommandBarProperties<T> {
  mobile: boolean;
  options: IGridViewOptions;
  gridTitle?: string;
  menucollapsed: boolean;
  hasSelection: boolean;
  commands?: IGridCommand<T>[];
  items: IRequestReference[];
  onSort?: (key: string) => void;
  onFilter?: () => void;
  onExport?: (filtered: boolean) => Promise<boolean | void>;
  onExportAnnee?: (filtered: boolean, annee : string) => Promise<boolean | void>;
  onApplyFilters?: (filters: IQueryFilter[], callback: () => void) => void;
  columns: IGridColumn<T>[];
  fullTextEnabled?: boolean;
  isDefaultOptions?: boolean;
  onShowDialog: (
    selectionRequired: boolean,
    confirmTitle: string | undefined,
    confirmMessage?: (items: T[]) => string | JSX.Element,
    confirmingCommand?: (items: T[], onCompleted: () => void) => void
  ) => void;
  onToggleGrid?: (mode: number) => void;
}

export interface IGridViewCommandBarState {
  searching: boolean;
  exporting: boolean;
}

export class GridViewCommandBar<T> extends React.Component<
  IGridViewCommandBarProperties<T>,
  IGridViewCommandBarState
> {
  private theme = getTheme();
  constructor(props: IGridViewCommandBarProperties<T>) {
    super(props);
    this.state = {
      searching: false,
      exporting: false,
    };
  }

  render(): JSX.Element {
    const { mobile, gridTitle } = this.props;
    const menuElements: IContextualMenuItem[] = (this.props.commands ?? []).map(
      (x) => {
        return {
          key: x.key,
          disabled: (x.selectionRequired ?? false) && !this.props.hasSelection,
          "data-automation-id": `nvx:grid:command:${x.key}`,
          name: x.name,
          subMenuProps: x.menuProps,
          iconProps: {
            iconName: x.icon,
          },
          onRender: x.onRenderContent,
          onClick:
            x.onClick === undefined
              ? undefined
              : () => {
                  x.selectionRequired ?? false
                    ? this.props.onShowDialog(
                        x.selectionRequired ?? false,
                        x.confirmTitle,
                        x.confirmMessage,
                        x.onClick
                      )
                    : x.onClick?.([], () => {});
                },
        };
      }
    );

    const menuItems = [];
    if (gridTitle !== undefined) {
      menuItems.push({
        key: "gridTitle",
        onRender: () => (
          <h3
            style={{
              marginLeft: this.props.menucollapsed ? "5px" : "0px",
              marginTop: "9px",
            }}
          >
            {gridTitle}
          </h3>
        ),
      });
    }
    menuItems.push(...menuElements);
    const searchId = uniqueId("gridview_fulltext_");
    const farMenuItems: IContextualMenuItem[] = [];
    const fullText: boolean = this.props.fullTextEnabled ?? true;
    if (fullText) {
      farMenuItems.push({
        key: "search",
        name: "",
        "data-automation-id": "nvx:grid:search",
        onRenderIcon: (_item?: IContextualMenuItemProps) => {
          return _this.state.searching ? (
            <TextField
              id={searchId}
              placeholder={i18n.t("global:search")}
              styles={{
                icon: {
                  cursor: "pointer",
                },
              }}
              iconProps={{
                iconName: "Search",
                style: {
                  cursor: "pointer",
                },
                onClick: (e) => {
                  if (_this.props.onApplyFilters !== undefined) {
                    _this.props.onApplyFilters(
                      [
                        {
                          name: "fulltext",
                          fieldName: "FullText",
                          type: "text",
                          value: (e.target as any).value,
                        },
                      ],
                      () => {}
                    );
                  }
                },
              }}
              autoFocus
              underlined={true}
              size={10}
              onBlur={() => {
                this.setState({ searching: false });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  if (_this.props.onApplyFilters !== undefined) {
                    _this.props.onApplyFilters(
                      [
                        {
                          name: "fulltext",
                          fieldName: "FullText",
                          type: "text",
                          value: (e.target as any).value,
                        },
                      ],
                      () => {}
                    );
                  }
                }
              }}
            />
          ) : (
            <Icon
              style={{
                cursor: "pointer",
                paddingLeft: "3px",
                color: this.theme.palette.themePrimary,
              }}
              iconName="Search"
              onClick={() => {
                this.setState({ searching: true });
                setTimeout(
                  () => document.getElementById(searchId)?.focus(),
                  300
                );
              }}
            />
          );
        },
      });
    }

    if (this.props.onApplyFilters !== undefined ) {
      farMenuItems.push({
        key: "filter",
        name: mobile ? "" : i18n.t("grid:filter"),
        "data-automation-id": "nvx:grid:filter",
        iconProps: {
          iconName: "FilterSettings",
        },
        onClick: this.props.onFilter,
      });
    }

    if (this.props.onExport) {
      if (mobile) {
        menuItems.push(this.getExportMenuItem());
      } else {
        farMenuItems.push(this.getExportMenuItem());
      }
    }

    if (this.props.onExportAnnee) {
      if (mobile) {
        menuItems.push(this.getExportMenuItemAnnee());
      } else {
        farMenuItems.push(this.getExportMenuItemAnnee());
      }
    }

    this.props.onSort &&
      farMenuItems.push({
        key: "sort",
        name: mobile ? "" : i18n.t("grid:sort"),
        "data-automation-id": "nvx:grid:sort",
        iconProps: {
          iconName: "SortLines",
        },
        subMenuProps: {
          items: this.props.columns
            .filter((x) => x.name)
            .map((x) => {
              const { desc, sortBy } = this.props.options;
              return {
                key: `sort:${x.key}`,
                "data-automation-id": `nvx:grid:sort:${x.key}`,
                text: i18n.t(x.name),
                style: {
                  fontWeight: sortBy === x.key ? "bold" : "normal",
                },
                onClick: () => this.props.onSort && this.props.onSort(x.key),
                iconProps: {
                  iconName:
                    sortBy === x.key
                      ? !desc
                        ? "SortLines"
                        : "SortLinesAscending"
                      : "SortLinesAscending",
                },
              };
            }),
        },
      });
    this.props.onToggleGrid &&
      farMenuItems.push({
        key: "tile",
        name: mobile ? "" : i18n.t(this.props.options.title),
        "data-automation-id": "nvx:grid:views",
        iconProps: {
          iconName: this.props.options.iconName,
        },
        subMenuProps: {
          items: [
            {
              key: "view_gridview",
              "data-automation-id": "nvx:grid:views:tiles",
              text: i18n.t("grid:gridview"),
              style: {
                fontWeight:
                  this.props.options.iconName === "Tiles" ? "bold" : "normal",
              },
              iconProps: { iconName: "Tiles" },
              onClick: () =>
                this.props.onToggleGrid &&
                this.props.onToggleGrid(GridViewMode.tiles),
            },
            {
              key: "view_list",
              "data-automation-id": "nvx:grid:views:list",
              text: i18n.t("grid:list"),
              style: {
                fontWeight:
                  this.props.options.iconName === "GroupedList"
                    ? "bold"
                    : "normal",
              },
              iconProps: { iconName: "GroupedList" },
              onClick: () =>
                this.props.onToggleGrid &&
                this.props.onToggleGrid(GridViewMode.list),
            },
            {
              key: "view_summary",
              "data-automation-id": "nvx:grid:views:summary",
              text: i18n.t("grid:summary"),
              style: {
                fontWeight:
                  this.props.options.iconName === "ClipboardList"
                    ? "bold"
                    : "normal",
              },
              iconProps: { iconName: "ClipboardList" },
              onClick: () =>
                this.props.onToggleGrid &&
                this.props.onToggleGrid(GridViewMode.summary),
            },
          ],
        },
      });

    const _this = this;

    return (
      <>
        <Modal isOpen={this.state.exporting} isBlocking={true}>
          <Stack
            styles={{
              root: {
                margin: this.props.mobile ? "10px" : "35px",
              },
            }}
            grow
            horizontal
            verticalFill
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Text
              variant="xLarge"
              styles={{ root: { fontWeight: 600, maxWidth: "300px" } }}
            >
              {i18n.t("global:export:wait")}
            </Text>
          </Stack>
        </Modal>
        <CommandBar
          styles={{
            root: {
              borderBottom: this.props.mobile
                ? "1px solid rgb(182 182 182 / 75%)"
                : undefined,
              marginTop: this.props.mobile ? "2px" : "3px",
              borderRadius: "6px",
              boxShadow:
                "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px",
              transition: "box-shadow 0.5s ease 0s",
              paddingLeft: this.props.gridTitle === undefined ? 0 : "14px",
              marginBottom: "3px",
            },
          }}
          items={menuItems}
          farItems={farMenuItems}
        />
      </>
    );
  }

  private getExportMenuItemAnnee = (): IContextualMenuItem => {
    const items = this.props.items as { name: string }[];

    const currentYear = new Date().getFullYear();

    // Extraire et compléter les années
    const years = items
      .map(item => {
          const match = item.name.match(/^(\d{2})-/); // Trouver l'année au début de la chaîne
          if (match) {
              const year = parseInt(match[1], 10);
              // Compléter l'année, par exemple, "22" devient "2022"
              return year + 2000;
          }
          return null;
      })
      .filter((year): year is number => year !== null && year <= currentYear) // Filtrer les null et années futures
      .filter((year, index, self) => self.indexOf(year) === index) // Eliminer les doublons
      .filter(year => year >= currentYear - 4) // Garder les 5 dernières années
      .sort((a, b) => b - a); // Trier les années du plus récent au plus ancien

    // Construire les éléments de sous-menu pour les années
    const anneeItems = years.map((annee) => ({
        key: `export_${annee}`,
        name: this.props.mobile ? "" : `${i18n.t("grid:export:annee")} ${annee}`,
        "data-automation-id": `nvx:grid:export:${annee}`,
        iconProps: {
            styles: {
                root: {
                    color: DefaultPalette.green,
                },
            },
            iconName: "ExcelDocument",
        },
        onClick: () => {
            if (this.props.onExportAnnee !== undefined) {
                this.setState({
                    exporting: true,
                });
                this.props
                    .onExportAnnee(false, annee?.toString() ?? "0")
                    .then(() => {
                        this.setState({
                            exporting: false,
                        });
                    })
                    .catch(() => {
                        this.setState({
                            exporting: false,
                        });
                    });
            }
        },
    }));

    // Ajouter les éléments existants après les éléments des années
    const subMenuItems = [
        ...anneeItems,  // Ajouter les éléments générés à partir de la liste des années en premier
       
        {
            key: "export_full",
            name:
                this.props.mobile && this.props.commands !== undefined
                    ? ""
                    : i18n.t("grid:export:full"),
            "data-automation-id": "nvx:grid:export",
            iconProps: {
                styles: {
                    root: {
                        color: DefaultPalette.green,
                    },
                },
                iconName: "ExcelDocument",
            },
            onClick: () => {
                if (this.props.onExportAnnee !== undefined) {
                    this.setState({
                        exporting: true,
                    });
                    this.props
                        .onExportAnnee(false, "0")
                        .then(() => {
                            this.setState({
                                exporting: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                exporting: false,
                            });
                        });
                }
            },
        },
    ];

    if ((this.props.isDefaultOptions ?? false) === true) {
        return {
            key: "export",
            name: this.props.mobile ? "" : i18n.t("grid:export"),
            "data-automation-id": "nvx:grid:export",
            iconProps: {
                styles: {
                    root: {
                        color: DefaultPalette.green,
                    },
                },
                iconName: "ExcelDocument",
            },
            onClick: () => {
                if (this.props.onExport !== undefined) {
                    this.setState({
                        exporting: true,
                    });
                    this.props
                        .onExport(false)
                        .then(() => {
                            this.setState({
                                exporting: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                exporting: false,
                            });
                        });
                }
            },
        };
    }

    return {
        key: "export",
        name: this.props.mobile ? "" : i18n.t("grid:export"),
        "data-automation-id": "nvx:grid:export",
        iconProps: {
            styles: {
                root: {
                    color: DefaultPalette.green,
                },
            },
            iconName: "ExcelDocument",
        },
        subMenuProps: {
            items: subMenuItems,
        },
    };
};

private getExportMenuItem = (): IContextualMenuItem => {
  if ((this.props.isDefaultOptions ?? false) === true) {
    return {
      key: "export",
      name: this.props.mobile ? "" : i18n.t("grid:export"),
      "data-automation-id": "nvx:grid:export",
      iconProps: {
        styles: {
          root: {
            color: DefaultPalette.green,
          },
        },
        iconName: "ExcelDocument",
      },
      onClick: () => {
        if (this.props.onExport !== undefined) {
          this.setState({
            exporting: true,
          });
          this.props
            .onExport(false)
            .then(() => {
              this.setState({
                exporting: false,
              });
            })
            .catch(() => {
              this.setState({
                exporting: false,
              });
            });
        }
      },
    };
  }
  return {
    key: "export",
    name: this.props.mobile ? "" : i18n.t("grid:export"),
    "data-automation-id": "nvx:grid:export",
    iconProps: {
      styles: {
        root: {
          color: DefaultPalette.green,
        },
      },
      iconName: "ExcelDocument",
    },
    subMenuProps: {
      items: [
        {
          key: "export_filtered",
          name:
            this.props.mobile && this.props.commands !== undefined
              ? ""
              : i18n.t("grid:export:filtered"),
          "data-automation-id": "nvx:grid:export:filtered",
          iconProps: {
            styles: {
              root: {
                color: DefaultPalette.green,
              },
            },
            iconName: "ExcelDocument",
          },
          onClick: () => {
            if (this.props.onExport !== undefined) {
              this.setState({
                exporting: true,
              });
              this.props
                .onExport(true)
                .then(() => {
                  this.setState({
                    exporting: false,
                  });
                })
                .catch(() => {
                  this.setState({
                    exporting: false,
                  });
                });
            }
          },
        },
        {
          key: "export_full",
          name:
            this.props.mobile && this.props.commands !== undefined
              ? ""
              : i18n.t("grid:export:full"),
          "data-automation-id": "nvx:grid:export",
          iconProps: {
            styles: {
              root: {
                color: DefaultPalette.green,
              },
            },
            iconName: "ExcelDocument",
          },
          onClick: () => {
            if (this.props.onExport !== undefined) {
              this.setState({
                exporting: true,
              });
              this.props
                .onExport(false)
                .then(() => {
                  this.setState({
                    exporting: false,
                  });
                })
                .catch(() => {
                  this.setState({
                    exporting: false,
                  });
                });
            }
          },
        },
      ],
    },
  };
};

}
