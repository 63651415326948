class EnvironmentService {
  public getEnvironmentName(): [string, string | undefined] {
    if (document.cookie) {
      const cookies = document.cookie
        .split(";")
        .map(function (c) {
          return c.trim().split("=").map(decodeURIComponent);
        })
        .reduce(function (a, b) {
          try {
            a[b[0]] = JSON.parse(b[1]);
          } catch (e) {
            a[b[0]] = b[1];
          }
          return a;
        }, {});

      return [cookies["amigo-sys-version"], cookies["amigo-sys-env"]];
    }

    return this.getEnvironmentFallback();
  }

  public getEnvironmentFallback(): [string, string | undefined] {
    return [
      "2.0.9",
      document.location.host.indexOf("fermequebec") === -1
        ? document.location.host.indexOf(".com") === -1
          ? "dev"
          : "test"
        : undefined,
    ];
  }
}

export default new EnvironmentService();
