import { IAddress } from "../IAddress";
import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface IInspectionAddress {
  id: number;
  branchId?: number;
  buildingCount: number;
  participantsCount: number;
  externalParticipantsCount?: number;
  address: IAddress;
  isHousingOwner: boolean;
  bedCount: number;
  isRentedToOtherEmployers: boolean;
  ownerFullName: string;
}

export interface INewInspectionRequest {
  enterpriseId: number;
  itemId: number;
  contactId?: number;
  contactName?: string;
  phone?: string;
  email?: string;
  estimatedDeposit?: string;
  lastInspectionDate?: string;
  comments: string;
  confirmed?: string;
  warned?: string;
  takeForeignerWorkersOutOfFerme: boolean;
  sendReportToCEACenter: boolean;
  agriculturalEmploymentCenterId?: number;
  ceaCenterNameAndEmail?: string;
  addresses: IInspectionAddress[];
}

export const NewInspectionRequestSchema: ISchemaObject = {
  name: "inspection",
  plural: "inspections",
  label: "NewInspection",
  fields: {
    confirmed: {
      name: "confirmed",
      label: "confirmed",
      required: true,
      pattern: "",
      errorMessage: "confirmed",
      type: "string",
    },
    contactName: {
      name: "contactName",
      label: i18n.t("messages:assisted:inspection:contactname"),
      required: true,

      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:contactname:error"),
      type: "string",
    },
    phone: {
      name: "phone",
      label: i18n.t("messages:assisted:inspection:phone"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:phone:error"),
      type: "string",
    },
    email: {
      name: "email",
      label: i18n.t("messages:assisted:inspection:email"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:email:error"),
      type: "string",
    },
    estimatedDeposit: {
      name: "estimatedDeposit",
      label: i18n.t("messages:assisted:inspection:estimateddeposit"),
      required: false,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:inspection:estimateddeposit:error"
      ),
      type: "string",
    },
    lastInspectionDate: {
      name: "estimatedDeposit",
      label: i18n.t("messages:assisted:inspection:lastinspection"),
      required: false,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:lastinspection:error"),
      type: "string",
    },
    agriculturalEmploymentCenterId: {
      name: "agriculturalEmploymentCenterId",
      label: i18n.t("messages:assisted:inspection:ceaCenterEmail"),
      required: false,

      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:ceaCenterEmail:error"),
      type: "number",
    },
  },
};

export const UpdateRequiredAgriculturalEmploymentCenterOnCondition = (condition: boolean) => {
  NewInspectionRequestSchema.fields.agriculturalEmploymentCenterId.required = condition;
}

export const InspectionLocationSchema: ISchemaObject = {
  name: "inspectionaddress",
  plural: "inspectionaddresses",
  label: "InspectionAddress",
  fields: {
    buildingCount: {
      name: "buildingCount",
      label: i18n.t("messages:assisted:inspection:building"),
      required: true,
      min: 1,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:building:error"),
      type: "number",
    },
    participantsCount: {
      name: "participantsCount",
      label: i18n.t("messages:assisted:inspection:workers"),
      required: true,
      min: 1,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:building:error"),
      type: "number",
    },
    extranalParticipantsCount: {
      name: "participantsCount",
      label: i18n.t("messages:assisted:inspection:workers"),
      required: true,
      min: 0,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:inspection:building:error"),
      type: "number",
    },
  },
};

export const InspectionAddressSchema: ISchemaObject = {
  name: "inspectionaddress",
  plural: "inspectionaddresses",
  label: "InspectionAddress",
  fields: {
    street: {
      name: "street",
      label: i18n.t("address:street"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("address:street:error"),
      type: "string",
    },
    city: {
      name: "city",
      label: i18n.t("address:city"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("address:city:error"),
      type: "string",
    },
    postalcode: {
      name: "postalcode",
      label: i18n.t("address:postalcode"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("address:postalcode:error"),
      type: "string",
    },
    state: {
      name: "state",
      label: i18n.t("address:state"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("address:state:error"),
      type: "string",
    },
  },
};