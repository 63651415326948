import {
  DetailsRow,
  IColumn,
  IContextualMenuItem,
  IDetailsListProps,
  IDetailsRowStyles,
  IRectangle,
  List,
  Selection,
  ShimmeredDetailsList,
  Text,
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/Utilities";
import * as React from "react";
import i18n from "src/services/i18n";
import { uniqueId } from "../../utils/uniqueId";
import { EntityPanel } from "../EntityPanel";
import {
  FilterPane,
  GridViewCommandBar,
  GridViewDialog,
  GridViewHelper,
  GridViewOptionsBuilder,
  IGridViewProps,
  IGridViewState,
  InfiniteScroll,
  IQueryFilter,
} from "./GridView.imports";
import { getGridViewClassNames } from "./GridView.styles";
import { GridViewMode } from "./GridView.types";
import { ShimmeredList } from "./ShimmeredList";

export class GridViewComponent<T> extends React.Component<
  IGridViewProps<T>,
  IGridViewState<T>
> {
  // #region Private Members
  private _columnCount: number = 0;
  private _columnWidth: number = 0;
  private _lastTap: number;
  private timeout?: any;
  private _list: List<T> | null = null;
  private _resolveList = (list: List<T>): void => {
    this._list = list;
  };

  private _toggleGridView = (mode: number): void => {
    const options = GridViewOptionsBuilder.getOptions(
      mode,
      this.props.options.sortBy,
      this.props.options.desc
    );
    options.fetched = this.props.options.fetched;
    this.props.onOptionsChanged && this.props.onOptionsChanged(options);
  };

  private _sort = (key: string): void => {
    this.props.onSort &&
      this.props.onSort(
        key,
        GridViewHelper.isDescending(key, this.props.options)
      );
  };

  private _queryFilters = (): void => {
    if (
      this.props.onQueryFilters !== undefined &&
      (this.queryFiltersRequired() ||
        this.state.language !== i18n.getLanguage())
    ) {
      this.props.onQueryFilters(false).then((x) => {
        this.setState({
          language: i18n.getLanguage(),
          filters: x,
        });
      });
    }

    this.setState({ filtering: true });
  };

  private _resetFilters = (): Promise<IQueryFilter[] | undefined> => {
    return this.props.onQueryFilters
      ? this.props.onQueryFilters(true)
      : new Promise<IQueryFilter[]>((resolve) => {
          resolve(this.state.filters ?? []);
        });
  };

  private queryFiltersRequired = (): boolean => {
    return (
      this.state.filters === undefined ||
      this.state.filters.length === 0 ||
      (this.state.filters.length === 1 &&
        this.state.filters.filter((x) => x.name === "fulltext").length === 1)
    );
  };

  private _applyFilters = (filters: IQueryFilter[]) => {
    if (this.props.onApplyFilters !== undefined) {
      this.props.onApplyFilters(filters, () => {
        this.setState({
          filtering: false,
        });
      });
    }
  };

  // GridViewHelper []
  public _resizeMode = (): void => {
    if (
      window.innerWidth < 599 &&
      this.props.options.mode === GridViewMode.list
    ) {
      this._toggleGridView(GridViewMode.tiles);
    }
  };

  // GridViewHelper []
  public _onSelectionChanged = () => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(
        this.state.selection.getSelection() as T[],
        this.state.selection
      );
    } else this.forceUpdate();
  };
  // #endregion

  constructor(props: IGridViewProps<T>) {
    super(props);
    this._lastTap = 0;
    GridViewHelper.init(this);
    this.ensureSelection();
  }

  private _showDialog = (
    _selectionRequired: boolean,
    confirmTitle: string | undefined,
    confirmMessage?: (items: T[]) => string | JSX.Element,
    confirmingCommand?: (items: T[], onCompleted: () => void) => void,
    selectedItems?: T[]
  ): void => {
    const els = selectedItems ?? (this.state.selection.getSelection() as T[]);

    if (confirmTitle === undefined || confirmTitle.length === 0) {
      confirmingCommand?.(els, () => this._closeDialog(false));
    } else {
      this.setState({
        isConfirming: true,
        confirmTitle: confirmTitle,
        confirmMessage: confirmMessage?.(els) ?? "",
        confirmingCommand: confirmingCommand,
      });
    }
  };

  private _closeDialog = (result: boolean): void => {
    if (result) {
      this.state.confirmingCommand?.(
        this.props.openedItem === undefined
          ? this.state.selection.getSelection().map((x) => x as T)
          : [this.props.openedItem],
        () => this._closeDialog(false)
      );
    } else {
      this.setState({
        isConfirming: false,
        confirmMessage: "",
        confirmTitle: "",
        confirmingCommand: (_items: T[]) => {},
      });
    }
    this.props.entityPanelProps?.onDismiss();
  };

  private filterPane = (): JSX.Element => {
    return this.props.onApplyFilters === undefined ? (
      <React.Fragment />
    ) : (
      <FilterPane
        {...this.props}
        styles={undefined}
        onApplyFilters={
          this.props.onApplyFilters === undefined
            ? undefined
            : this._applyFilters
        }
        onResetFilters={
          this.props.onApplyFilters === undefined
            ? undefined
            : this._resetFilters
        }
        filters={this.state.filters === undefined ? [] : this.state.filters}
        enabled={this.state.filtering}
        onDismiss={() => {
          this.setState({
            filtering: false,
          });
        }}
      />
    );
  };

  private ensureSelection = (): void => {
    const { items, isItemSelected } = this.props;
    const { selection } = this.state;
    items &&
      isItemSelected &&
      items.length !== (selection.getItems()?.length ?? 0) &&
      selection.setItems(items);

    const indexes: any = isItemSelected
      ? items.filter((z) => isItemSelected(z)).map((z) => (z as any).key)
      : [];

    items &&
      isItemSelected &&
      items.forEach((x) => {
        if (selection.isKeySelected((x as any).key)) {
          if (indexes.filter((y: any) => y === (x as any).key).length === 0) {
            selection.setKeySelected((x as any).key, false, false);
          }
        } else if (
          indexes.filter((y: any) => y === (x as any).key).length > 0
        ) {
          selection.setKeySelected((x as any).key, true, false);
        }
      });
  };

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const { theme, isItemSelectable } = this.props;
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const selectableStyle =
        isItemSelectable?.(props.item) ?? true
          ? {}
          : {
              ":hover": {
                cursor: "not-allowed!important",
              },
              ".ms-DetailsRow-check.ms-Check-checkHost": {
                visibility: "hidden",
              },
            };
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = {
          ...selectableStyle,
        };
      } else {
        customStyles.root = {
          backgroundColor: theme.palette.neutralLighter,
          ...selectableStyle,
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  componentDidUpdate() {
    this.ensureSelection();
  }

  render() {
    const {
      styles,
      options,
      scrollableTargetId,
      columns,
      onSort,
      onRenderHeader,
    } = this.props;
    const _this = this;

    const id: string = uniqueId("nvx_gridview_viewport_");
    const tid: string = uniqueId("nvx_gridview_viewport_");
    const [classNames] = getGridViewClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const _cols: IColumn[] = GridViewHelper.getColumns(
      columns,
      options,
      onSort
    );

  

    const withCommands =
      (this.props.commands ?? []).length > 0 ||
      this.props.onExport ||
      this.props.onSort ||
      this.props.onOptionsChanged ||
      this.props.onQueryFilters;

    return (
      <div className={classNames.root}>
        <GridViewDialog {...this.state} onDismiss={this._closeDialog} />
        {this.props.onQueryFilters && this.filterPane()}
        {withCommands && (
          <GridViewCommandBar
            {...this.props}
            onSort={
              this.props.onSort ? (key: string) => _this._sort(key) : undefined
            }
            items={this.props.items as any}
            menucollapsed={this.props.tokens.menucollapsed}
            hasSelection={this.state.selection.getSelectedCount() > 0}
            options={options}
            onShowDialog={this._showDialog}
            onToggleGrid={
              this.props.onOptionsChanged ? this._toggleGridView : undefined
            }
            onFilter={() => {
              _this._queryFilters();
            }}
          />
        )}
        {this.props.totalCount !== undefined && (
          <div>
            <Text>{`${this.props.totalCount} ${i18n.t(
              this.props.totalCount === 1 ? "global:item" : "global:items"
            )}`}</Text>
          </div>
        )}
        {this.props.beforeList}
        <div
          id={scrollableTargetId === undefined ? id : undefined}
          className={classNames.gridContainer}
          onScroll={() => {
            document.dispatchEvent(
              new CustomEvent("scroll", { detail: document.getElementById(id) })
            );
            return true;
          }}
        >
          <InfiniteScroll
            dataLength={this.props.items.length}
            next={this.props.onDataPaging}
            hasMore={true}
            className={classNames.infiniteScroll}
            style={
              this.props.options.fetched && this.props.items.length === 0
                ? {
                    display: "none",
                    marginBottom: "20px",
                    backgroundColor: this.props.theme.palette.neutralLighter,
                  }
                : {
                    backgroundColor: this.props.theme.palette.neutralLighter,
                    marginBottom:
                      this.props.embedded ?? false ? undefined : "20px",
                    overflowX: "hidden",
                    width:
                      (this.props.embedded ?? false) ||
                      this.props.mobile ||
                      (this.props.inboxStyled ?? false)
                        ? undefined
                        : this.props.options.mode === GridViewMode.tiles
                        ? "calc(-134px + 100vw)"
                        : undefined,
                  }
            }
            scrollableTarget={scrollableTargetId ?? id}
            loader={<div></div>}
          >
            {!this.props.options.fetched &&
              this.props.options.mode === GridViewMode.tiles && (
                <ShimmeredList
                  mobile={this.props.mobile}
                  getItemCountForPage={this._getItemCountForPage}
                />
              )}
            {this.props.options.fetched &&
              this.props.options.mode === GridViewMode.tiles && (
                <List
                  id={tid}
                  ref={this._resolveList}
                  className={classNames.gridScrolling}
                  items={this.props.items}
                  getItemCountForPage={this._getItemCountForPage}
                  getPageSpecification={() => {
                    return {
                      itemCount:
                        this.props.items.length < 20
                          ? 20
                          : this.props.items.length,
                    };
                  }}
                  onRenderCell={(item?: T) => {
                    return this.props.onRenderTile !== undefined &&
                      item !== undefined
                      ? this.props.onRenderTile(
                          item,
                          this._columnWidth,
                          this.state.selection,
                          (sel: Selection) => {
                            this.setState({ selection: sel });
                            this._list != null && this._list.forceUpdate();
                          }
                        )
                      : undefined;
                  }}
                />
              )}
            {this.props.options.mode !== GridViewMode.tiles && (
              <ShimmeredDetailsList
                onRenderDetailsHeader={onRenderHeader}
                listProps={{
                  componentRef: this._resolveList as any,
                  getItemCountForPage: GridViewHelper.getItemCountForPage,
                  getPageSpecification: () => {
                    return {
                      itemCount:
                        this.props.items.length < 20
                          ? 20
                          : this.props.items.length,
                    };
                  },
                  onTouchEnd: (event: React.TouchEvent<HTMLDivElement>) => {
                    if (!_this.props.onItemInvoked) return;
                    const lastTap = _this._lastTap;
                    const currentTime = new Date().getTime();
                    const tapLength = currentTime - lastTap;
                    _this.timeout !== undefined && clearTimeout(_this.timeout);
                    if (tapLength < 500 && tapLength > 0) {
                      const items = _this.state.selection.getSelection();
                      if (items.length > 0) {
                        _this.props.onItemInvoked(
                          items[0] as T,
                          _this.state.selection.getSelectedIndices()[0]
                        );
                      }
                      event.preventDefault();
                    } else {
                      this.timeout = setTimeout(() => {
                        _this.timeout !== undefined &&
                          clearTimeout(_this.timeout);
                      }, 500);
                    }
                    this._lastTap = currentTime;
                  },
                }}
                selectionPreservedOnEmptyClick={
                  this.props.selectionPreservedOnEmptyClick ?? true
                }
                columns={
                  this.props.options.mode === GridViewMode.list
                    ? _cols
                    : _cols.slice(
                        this.props.summaryColumStartIndex ?? 0,
                        this.props.summaryColumCount ?? 1
                      )
                }
                onRenderItemColumn={
                  this.props.onRenderCell ?? this.onRenderItemColumn
                }
                selectionZoneProps={{
                  selection: this.state.selection,
                  isSelectedOnFocus: this.props.isSelectedOnFocus ?? true,
                  disableAutoSelectOnInputElements: true,
                  selectionPreservedOnEmptyClick:
                    this.props.selectionPreservedOnEmptyClick ?? true,
                  selectionMode: this.props.selectionMode,
                }}
                selectionMode={
                  this.props.selectionMode ?? SelectionMode.multiple
                }
                selection={this.state.selection}
                onItemInvoked={this.props.onItemInvoked}
                items={this.props.items}
                onActiveItemChanged={this.props.onActiveItemChanged}
                enableShimmer={!this.props.options.fetched}
                onRenderRow={this._onRenderRow}
              />
            )}
          </InfiniteScroll>
          {this.props.options.fetched && this.props.items.length === 0 && (
            <div className={classNames.emptyMessageContainer}>
              <Text as="h3" className={classNames.emptyMessage}>
                {this.props.emptyMessage ?? i18n.t("global:emptyitemsmessage")}
              </Text>
            </div>
          )}
        </div>
        {this.props.entityPanelProps && (
          <EntityPanel
            {...this.props.entityPanelProps}
            styles={undefined}
            onDismiss={() => {
              if (!this.state.isConfirming) {
                this.props.entityPanelProps?.onDismiss();
              }
            }}
            commands={
              this.props.openedItem && this.props.entityPanelProps.commands
                ? this.props.entityPanelProps.commands.map((x) => {
                    return {
                      key: x.key,
                      disabled: x.disabled,
                      "data-automation-id": `nvx:grid:command:${x.key}`,
                      name: x.text ?? x.name,
                      iconProps: x.iconProps ?? {
                        iconName: x.icon,
                      },
                      onClick: (
                        ev?:
                          | React.MouseEvent<HTMLElement, MouseEvent>
                          | React.KeyboardEvent<HTMLElement>,
                        item?: IContextualMenuItem
                      ) => {
                        const { openedItem } = this.props;
                        this._showDialog(
                          true,
                          x.confirmTitle,
                          x.confirmMessage,
                          (_items: T[]) => x.onClick && x.onClick(ev, item),
                          openedItem !== undefined ? [openedItem] : []
                        );
                      },
                    };
                  })
                : this.props.openedItem && this.props.commands
                ? this.props.commands
                    .filter((x) => x.selectionRequired)
                    .map((x) => {
                      return {
                        key: x.key,
                        disabled: false,
                        "data-automation-id": `nvx:grid:command:${x.key}`,
                        name: x.name,
                        iconProps: {
                          iconName: x.icon,
                        },
                        onClick: () => {
                          const { openedItem } = this.props;
                          this._showDialog(
                            true,
                            x.confirmTitle,
                            x.confirmMessage,
                            x.onClick,
                            openedItem !== undefined ? [openedItem] : []
                          );
                        },
                      };
                    })
                : undefined
            }
          />
        )}
      </div>
    );
  }

  private onRenderItemColumn = (
    item?: any,
    index?: number,
    column?: IColumn
  ) => {
    return column === undefined || column.fieldName === undefined ? (
      <React.Fragment />
    ) : (
      <Text
        data-automation-id={`nvx:gridview:items:${index}:${column.fieldName}`}
        onClick={
          this.props.onItemInvoked
            ? () => {
                return this.props.onItemInvoked?.(item, index ?? 0);
              }
            : undefined
        }
        style={
          this.props.onItemInvoked
            ? {
                cursor: "pointer",
              }
            : undefined
        }
      >
        {item[column.fieldName]}
      </Text>
    );
  };

  private _getItemCountForPage = (
    itemIndex?: number,
    surfaceRect?: IRectangle
  ): number => {
    const ROWS_PER_PAGE = 5;
    const MAX_ROW_HEIGHT = 220;
    if (itemIndex === 0) {
      let cc = Math.ceil((surfaceRect?.width ?? 220) / MAX_ROW_HEIGHT);
      if (cc === undefined) cc = 2;
      this._columnWidth = Math.floor((surfaceRect?.width ?? 220) / cc);
      this._columnCount = cc;
    }
    return this._columnCount * ROWS_PER_PAGE;
  };
}
