import { CommandBar } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getNewMessageCommandBarClassNames } from "./NewMessageCommandBar.styles";
import {
  INewMessageCommandBarProps,
  INewMessageCommandBarState,
} from "./NewMessageCommandBar.types";

export class NewMessageCommandBarComponent extends React.Component<
  INewMessageCommandBarProps,
  INewMessageCommandBarState
> {
  render(): JSX.Element {
    const { styles, confirming, canSave } = this.props;
    const [classNames] = getNewMessageCommandBarClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <CommandBar
          className="MessageCommandBar"
          items={
            confirming
              ? [
                {
                  key: "send",
                  text: i18n.t("messages:send"),
                  iconProps: { iconName: "Send" },
                  onClick: () => {
                    this.props.onSend();
                  },
                  buttonStyles:{
                    root: {
                      backgroundColor: '#CB511B',
                      color: 'white',
                      fontWeight: 'bold',
                    },
                    icon: {
                      color: 'white',
                    }
                  },
                },
                ]
              : [
                  {
                    key: "send",
                    text: i18n.t("messages:send"),
                    iconProps: { iconName: "Send" },
                    onClick: () => {
                      this.props.onSend();
                    },
                    buttonStyles:{
                      root: {
                        backgroundColor: '#CB511B',
                        color: 'white',
                        fontWeight: 'bold',
                      },
                      icon: {
                        color: 'white',
                      }
                    },
                  },
                  {
                    key: "attach",
                    text: i18n.t("messages:attach"),
                    iconProps: { iconName: "Attach" },
                    onClick: () => {
                      this.props.onAttach();
                    },
                  },
                  {
                    key: "save",
                    disabled: !canSave,
                    text: i18n.t("messages:save"),
                    iconProps: { iconName: "Save" },
                    onClick: () => {
                      this.props.onSave();
                    },
                  },
                  {
                    key: "discard",
                    text: i18n.t("messages:discard"),
                    iconProps: { iconName: "NavigateBack" },
                    onClick: () => {
                      this.props.onDismiss(false, false);
                    },
                  },
                ]
          }
          ariaLabel={i18n.t("commandbar:arialabel")}
        />
      </div>
    );
  }
}
