import { Selection, Text } from "@fluentui/react";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { UserCallout } from "src/controls/UserCallout";
import { objectId } from "src/models/IObjectReference";
import { IRequestReference } from "src/models/Requests/IRequestReference";
import CountriesService from "src/services/CountriesService";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import RequestsService from "src/services/Requests/RequestsService";
import { RequestCard } from "../RequestCard";
import { RequestReportsPanel } from "../RequestReportsPanel";
import { getRequestsControlClassNames } from "./RequestsControl.styles";
import {
  IRequestsControlProps,
  IRequestsControlState,
} from "./RequestsControl.types";
import { CountryIcon } from "src/components/CountryIcon";
import { Stack, SelectionMode } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";

export class RequestsControlComponent extends React.Component<
  IRequestsControlProps,
  IRequestsControlState
> {
  constructor(props: IRequestsControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "requests",
        GridViewMode.tiles,
        "name",
        true
      ),
      items: [],
      filters: PreferencesService.getFiltersOrDefault("requests", false, () => [
        {
          name: "requests:requestnumber",
          fieldName: "name",
          type: "text",
          value: null,
        },
        {
          name: "requests:createdon",
          fieldName: "dateCreation",
          type: "date",
          value: null,
        },
        {
          name: "requests:status",
          fieldName: "status",
          type: "choice",
          value: [
            {
              id: "Confirmée",
              name: "requests:status:confirmed",
            },
          ],
          choices: [
            {
              id: "Confirmée",
              name: "requests:status:confirmed",
            },
            {
              id: "Fermée",
              name: "requests:status:closed",
            },
          ],
        },
      ]),
      printing: false,
    };
    RequestsService.init();
    RequestsService.getRequestReferences(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  render(): JSX.Element {
    const { className, styles } = this.props;
    const [classNames, subComponentStyles] = getRequestsControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={`${className} ${classNames.root}`}>
        <div className="ms-RequestControl" style={{ height: "100%" }}>
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                margin:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "0 4px 10px 4px",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "0 10px 10px 10px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={subComponentStyles?.gridView}
                selectionMode={SelectionMode.single}
                onItemInvoked={(item: IRequestReference) => {
                  this.props.route.history.push(
                    "/demandes/" + item.key.toString()
                  );
                }}
                onRenderTile={(
                  item: IRequestReference,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <RequestCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onSelected={(item: IRequestReference) => {
                        const isSelected: boolean =
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length > 0;
                        ensureSelection(selection, item, true);
                        selection.setKeySelected(
                          item.key.toString(),
                          !isSelected,
                          false
                        );
                        callback(selection);
                      }}
                      onClick={(item: IRequestReference) => {
                        this.props.route.history.push(
                          "/demandes/" + item.key.toString()
                        );
                      }}
                      onPrint={(item: IRequestReference) => {
                        this.setState({
                          printing: true,
                          printingRequestId: item.key,
                          printingVersionNumber: parseInt(item.version),
                        });
                      }}
                    />
                  );
                }}
                onExportAnnee={(filtered, annee) =>
                  RequestsService.exportRequests(
                    objectId(this.props.enterprise),
                    annee,
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filtered ? this.state.filters : []
                  )
                }
                onSort={(fieldName: string, desc: boolean) => {
                  RequestsService.init();
                  RequestsService.getRequestReferences(
                    objectId(this.props.enterprise),
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: PreferencesService.asCommittedOptions(
                        GridViewOptionsBuilder.getOptions(
                          this.state.options.mode,
                          fieldName,
                          desc,
                          true
                        ),
                        "requests"
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                options={this.state.options}
                onOptionsChanged={(options: IGridViewOptions) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      options,
                      "requests"
                    ),
                  });
                }}

                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  RequestsService.init();
                  RequestsService.getRequestReferences(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: PreferencesService.asCommittedFilters(
                        filters,
                        "requests"
                      ),
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                
                onQueryFilters={(reset: boolean) => {
                  return CountriesService.getCountries(
                    objectId(this.props.enterprise)
                  ).then((x) => {
                    return PreferencesService.getFiltersOrDefault(
                      "requests",
                      reset,
                      () => [
                        {
                          name: "requests:requestnumber",
                          fieldName: "name",
                          type: "text",
                          value: reset
                            ? null
                            : this.state.filters.filter(
                                (x) => x.fieldName === "name"
                              )?.[0].value,
                        },
                        {
                          name: "requests:createdon",
                          fieldName: "dateCreation",
                          type: "date",
                          value: reset
                            ? null
                            : this.state.filters.filter(
                                (x) => x.fieldName === "dateCreation"
                              )?.[0].value,
                        },
                        {
                          name: "requests:country",
                          fieldName: "country",
                          type: "choice",
                          value: x,
                          choices: x,
                        },
                        {
                          name: "requests:status",
                          fieldName: "status",
                          type: "choice",
                          value: reset
                            ? null
                            : this.state.filters.filter(
                                (x) => x.fieldName === "status"
                              )?.[0].value,
                          choices: [
                            {
                              id: "Confirmée",
                              name: "requests:status:confirmed",
                            },
                            {
                              id: "Fermée",
                              name: "requests:status:closed",
                            },
                          ],
                        },
                      ]
                    ).map((h) =>
                      h.fieldName === "country"
                        ? {
                            name: "requests:country",
                            fieldName: "country",
                            type: "choice",
                            value: x,
                            choices: x,
                          }
                        : h
                    );
                  });
                }}
                columns={[
                  {
                    key: "name",
                    name: "requests:requestnumber",
                    minWidth: 170,
                    maxWidth: 270,
                  },
                  {
                    key: "agentLabel",
                    name: "requests:agent",
                    minWidth: 150,
                    onRenderCell: (item: IRequestReference) => {
                      return (
                        <UserCallout
                          {...this.props}
                          styles={undefined}
                          user={item.agent}
                        />
                      );
                    },
                  },
                  {
                    key: "country",
                    name: "requests:country",
                    minWidth: 120,
                    maxWidth: 170,
                    onRenderCell: (item: IRequestReference) => {
                      return (
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                          <Text>{item.country}</Text>
                          <CountryIcon
                            {...this.props}
                            styles={subComponentStyles?.country}
                            country={item.country}
                          />
                        </Stack>
                      );
                    },
                  },
                  {
                    key: "status",
                    name: "requests:status",
                    minWidth: 110,
                    maxWidth: 110,
                  },
                  {
                    key: "participantCount",
                    name: "requests:participantcount",
                    minWidth: 170,
                    maxWidth: 170,
                  },
                  /*
                  {
                    key: "nextevent",
                    name: "requests:nextevent",
                    minWidth: 170,
                    onRenderCell: this.renderContextualInfo,
                  },
                  */
                  {
                    key: "version",
                    name: "requests:version",
                    minWidth: 90,
                    maxWidth: 90,
                  },
                ]}
                onDataPaging={() => {
                  RequestsService.getRequestReferences(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                items={this.state.items}
                commands={[
                  {
                    key: "printrequest",
                    name: i18n.t("billing:printbill"),
                    icon: "PDF",
                    selectionRequired: true,
                    confirmTitle: undefined,
                    confirmMessage: (_items: IRequestReference[]) => {
                      return "";
                    },
                    onClick: (
                      selectedItems: IRequestReference[],
                      onCompleted: () => void
                    ) => {
                      selectedItems.length > 0 &&
                        this.setState({
                          printing: true,
                          printingRequestId: selectedItems[0].key,
                          printingVersionNumber: parseInt(
                            selectedItems[0].version
                          ),
                        });
                      onCompleted();
                    },
                  },
                ]}
              />
            </Card.Item>
          </Card>
        </div>
        <RequestReportsPanel
          {...this.props}
          requestId={this.state.printingRequestId ?? -1}
          versionNumber={this.state.printingVersionNumber ?? -1}
          isOpen={this.state.printing}
          onDismiss={() =>
            this.setState({
              printing: false,
              printingRequestId: undefined,
              printingVersionNumber: undefined,
            })
          }
          onPrint={(
            type: number,
            language: string,
            date: Date | undefined,
            callback: () => void
          ) => {
            const { printingRequestId, printingVersionNumber } = this.state;
            if (printingRequestId && printingVersionNumber) {
              RequestsService.print(
                type,
                objectId(this.props.enterprise),
                printingRequestId,
                printingVersionNumber,
                date,
                language
              ).then(() => {
                callback();
                this.setState({
                  printing: false,
                  printingRequestId: undefined,
                  printingVersionNumber: undefined,
                });
              });
            }
          }}
        />
      </div>
    );
  }
}
