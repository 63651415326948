import {
  Checkbox,
  Icon,
  IconButton,
  IPersonaProps,
  Label,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  StackItem,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { EditorState } from "draft-js";
import * as React from "react";
import GridViewDialog from "src/components/GridView/GridViewDialog";
import { ProgressPanel } from "src/components/ProgressPanel";
import { UsersPicker } from "src/components/UsersPicker";
import { objectId } from "src/models/IObjectReference";
import { CommunicationTypes } from "src/models/Messages/CommunicationTypes";
import { ITransportArrivalRequestDataModel } from "src/models/Messages/ITransportArrivalRequestDataModel";
import { IDepartureRequestDataModel } from "src/models/Messages/IDepartureRequestDataModel";
import { IDepartureRequestFlight } from "src/models/Messages/IDepartureRequestFlight";
import { IInspectionRequestDataModel } from "src/models/Messages/IInspectionRequestDataModel";
import {
  IAttachmentModel,
  INewMessageModel,
  NewMessageModelSchema,
} from "src/models/Messages/IMessageSummary";
import { INewTransportArrivalRequest } from "src/models/Messages/INewTransportArrivalRequest";
import { INewDepartureRequest } from "src/models/Messages/INewDepartureRequest";
import { INewInspectionRequest } from "src/models/Messages/INewInspectionRequest";
import { INewParticipantTransfertRequest } from "src/models/Messages/INewParticipantTransfertRequest";
import { IParticipantTransfertRequestDataModel } from "src/models/Messages/IParticipantTransfertRequestDataModel";
import { IUser } from "src/models/User";
import AuthService from "src/services/AuthService";
import DocumentService from "src/services/Documents/DocumentService";
import i18n from "src/services/i18n";
import { TransportArrivalRequestService } from "src/services/Messages/TransportArrivalRequestService";
import { DepartureRequestService } from "src/services/Messages/DepartureRequestService";
import { InspectionRequestService } from "src/services/Messages/InspectionRequestService";
import MessageService from "src/services/Messages/MessagesService";
import { ParticipantTransfertService } from "src/services/Messages/ParticipantTransfertService";
import RichTextService from "src/services/RichTextService";
import { validate } from "../../../utils/validator";
import { TransportArrivalAssistedRequestEditor } from "../TransportArrivalAssistedRequestEditor";
import { TransportArrivalAssistedRequestEditorComponent } from "../TransportArrivalAssistedRequestEditor/TransportArrivalAssistedRequestEditor.base";
import { AssistedMessageEditionContainer } from "../AssistedMessageEditionContainer";
import { CommunicationTypeSelector } from "../Controls/CommunicationTypeSelector";
import { MessageEditor } from "../Controls/MessageEditor";
import { NewMessageCommandBar } from "../Controls/NewMessageCommandBar";
import { DepartureAssistedEditor } from "../DepartureAssistedEditor";
import { DepartureAssistedEditorComponent } from "../DepartureAssistedEditor/DepartureAssistedEditor.base";
import { InspectionRequestEditor } from "../InspectionRequestEditor";
import { InspectionRequestEditorComponent } from "../InspectionRequestEditor/InspectionRequestEditor.base";
import { ParticipantTransfertAssistedEditor } from "../ParticipantTransfertAssistedEditor";
import { ArrivalAssistedEditor } from "../ArrivalAssistedEditor";
import { ArrivalAssistedEditorComponent } from "../ArrivalAssistedEditor/ArrivalAssistedEditor.base";
import { ParticipantTransfertAssistedEditorComponent } from "../ParticipantTransfertAssistedEditor/ParticipantTransfertAssistedEditor.base";
import {
  getNewMessageControlClassNames,
  INewMessageControlSubComponentStyles,
} from "./NewMessageControl.styles";
import {
  INewMessageControlProps,
  INewMessageControlState,
} from "./NewMessageControl.types";
import { INewArrivalRequest } from "src/models/Messages/INewArrivalRequest";
import { IArrivalRequestFlight } from "src/models/Messages/IArrivalRequestFlight";
import { IArrivalRequestDataModel } from "src/models/Messages/IArrivalRequestDataModel";
import { ArrivalRequestService } from "src/services/Messages/ArrivalRequestService";

export class NewMessageControlComponent extends React.Component<
  INewMessageControlProps,
  INewMessageControlState
> {
  private departureRef: React.RefObject<DepartureAssistedEditorComponent>;
  private arrivalRef: React.RefObject<ArrivalAssistedEditorComponent>;
  private transportArrivalRef: React.RefObject<TransportArrivalAssistedRequestEditorComponent>;
  private inspectionRef: React.RefObject<InspectionRequestEditorComponent>;
  private participantTransferRef: React.RefObject<ParticipantTransfertAssistedEditorComponent>;

  constructor(props: INewMessageControlProps) {
    super(props);
    this.departureRef = React.createRef();
    this.arrivalRef = React.createRef();
    this.transportArrivalRef = React.createRef();
    this.inspectionRef = React.createRef();
    this.participantTransferRef = React.createRef();
    const routeState = this.props.route.location.state as any;
    const to =
      routeState === undefined || routeState.recipients === undefined
        ? props.inputMessage?.to ?? []
        : (routeState.recipients as IUser[]);

    const communicationType: string = props.replyType
      ? CommunicationTypes.Standard
      : props.inputMessage?.type.id.toString() ?? CommunicationTypes.Standard;

    const replyMesage: string | undefined =
      props.inputMessage && props.replyType
        ? `<p>-----------------------<br />${i18n.t(
            "messages:reply:format:from"
          )}: ${props.inputMessage.from.name} (${
            props.inputMessage.from.email
          })<br />${i18n.t("messages:reply:format:to")}:${props.inputMessage.to
            .map((x) => {
              return x.email;
            })
            .join(", ")}<br />${i18n.t("messages:reply:format:to")}: ${
            props.inputMessage.subject
          }<br />${i18n.t("messages:reply:format:created")}: ${
            props.inputMessage.timestamp
          }<br />-----------------------</p><br /><br /><br />${props.inputMessage.message
            .replace(/<div>/g, "<p>")
            .replace(/<\/div>/g, "</p>")}<br />`
        : undefined;

    this.state = {
      sendtransit: false,
      peopleList: [],
      attachments:
        props.replyType && props.replyType === "forward" && props.inputMessage
          ? props.inputMessage.attachments.map((x) => {
              return {
                ...x,
                data: undefined,
              };
            })
          : [],
      subject: `${
        props.replyType
          ? props.replyType === "forward"
            ? !props.inputMessage?.subject.startsWith("TR: ")
              ? "TR: "
              : ""
            : !props.inputMessage?.subject.startsWith("RE: ")
            ? "RE: "
            : ""
          : ""
      }${props.inputMessage?.subject ?? ""}`,
      message: replyMesage ?? props.inputMessage?.message ?? "",
      errors: [],
      departureRequestErrors: [],
      transportArrivalRequestErrors: [],
      inspectionRequestErrors: [],
      participantTransfertRequestErrors: [],
      arrivalRequestErrors: [],
      arrivalRequestFlightsFetched: false,
      arrivalRequestParticipantsFetched: false,
      departureRequestParticipantsFetched: false,
      departureRequestFlightsFetched: false,
      participantTransfertRequestParticipantFetched: false,
      transportArrivalRequestParticipantsFetched: false,
      confirming: false,
      communicationType: communicationType,
      designations:
        props.inputMessage && !props.replyType
          ? props.inputMessage.designations
          : [],
      recipients:
        props.replyType && props.inputMessage
          ? props.replyType === "forward"
            ? []
            : [props.inputMessage.from]
          : to,
      departureRequest:
        communicationType === CommunicationTypes.DepartureRequest ||
        communicationType === CommunicationTypes.ChangeDepartureRequest
          ? {
              preauth: false,
              landTransportIncluded: false,
              participants: [],
            }
          : undefined,
      errorMessage: undefined,
      sending: false,
      saving: false,
      onmessage: true,
      editorState: props.inputMessage?.message
        ? (EditorState.createWithContent(
            RichTextService.getContentState(
              replyMesage ?? props.inputMessage.message
            ) as any
          ) as any)
        : (EditorState.createEmpty() as any),
      transportArrivalRequest:
        communicationType === CommunicationTypes.TransportArrivalRequest
          ? {
              key: "0",
              id: this.props.inputMessage?.id ?? 0,
              enterpriseId: objectId(this.props.enterprise),
              isCustom: false,
              itemId: this.props.inputMessage?.id ?? 0,
              participants: [],
              errors: [],
            }
          : undefined,
      participantTransfertRequest:
        communicationType === CommunicationTypes.EmployerTransfert
          ? {
              key: "0",
              id: this.props.inputMessage?.id ?? 0,
              enterpriseId: objectId(this.props.enterprise),
              itemId: this.props.inputMessage?.id ?? 0,
              participants: [],
            }
          : undefined,
      arrivalRequest:
        communicationType === CommunicationTypes.ArrivalRequest
          ? {
              preauth: false,
              landTransportIncluded: false,
              participants: [],
            }
          : undefined,
      inspectionRequest:
        communicationType === CommunicationTypes.InspectionRequest
          ? {
              itemId: this.props.inputMessage?.id ?? 0,
              addresses: [],
              enterpriseId: objectId(this.props.enterprise),
              comments: "",
              takeForeignerWorkersOutOfFerme: false,
              sendReportToCEACenter: false,
            }
          : undefined,
    };

    MessageService.getRecentRecipients().then((x) =>
      this.setState({ peopleList: x.map(this.userToPersona) })
    );
  }

  private commTypeToLabel = (value: string): string => {
    switch (value) {
      case CommunicationTypes.Standard:
        return "";
      case CommunicationTypes.DepartureRequest:
        return "messages:assisted:newdeparture:title";
      case CommunicationTypes.ChangeDepartureRequest:
        return "messages:assisted:changedeparture:title";
      case CommunicationTypes.EmployerTransfert:
        return "messages:assisted:enterprisechange:title";
      case CommunicationTypes.TransportArrivalRequest:
        return "messages:assisted:newarrival:title";
      case CommunicationTypes.InspectionRequest:
        return "messages:assisted:inspection:title";
    }
    return "";
  };

  private userToPersona(user: IUser): IPersonaProps {
    return {
      itemID: user.id,
      imageUrl: undefined,
      text: user.name,
      secondaryText: user.role,
      tertiaryText: undefined,
      optionalText: user.email,
      presence: PersonaPresence.none,
    };
  }

  private isConfirmingInspection = () => {
    const { inspectionRequestErrors } = this.state;

    return (
      Object.keys(inspectionRequestErrors).length === 1 &&
      inspectionRequestErrors.confirmed !== undefined
    );
  };

  private onCommunicationTypeChanged = (communicationType: string) => {

    // force subject value when inspection is selected
    if(communicationType === CommunicationTypes.InspectionRequest)
    {
      this.setState({
        subject: i18n.t("messages:type:inspection"),
      });
    }
    else
    {
      this.setState({
        subject: "",
      });
    }

    const departureRequest: INewDepartureRequest | undefined =
      communicationType === CommunicationTypes.DepartureRequest ||
      communicationType === CommunicationTypes.ChangeDepartureRequest
        ? {
            preauth: false,
            landTransportIncluded: false,
            participants: [],
          }
        : undefined;

    if (
      !this.props.replyType &&
      communicationType === CommunicationTypes.InspectionRequest
    ) {
      MessageService.getInspectionRecipients().then((x) => {
        const designations: IUser[] = [];
        x.forEach((y) => {
          if (designations.filter((z) => z.id === y.id).length === 0) {
            designations.push(y);
          }
        });
        this.setState({
          designations: designations,
        });
      });
    } else if (
      !this.props.replyType &&
      communicationType === CommunicationTypes.EmployerTransfert
    ) {
      MessageService.getTransfertConctacts(
        objectId(this.props.enterprise)
      ).then((x) => {
        const designations: IUser[] = [];
        x.forEach((y) => {
          if (designations.filter((z) => z.id === y.id).length === 0) {
            designations.push(y);
          }
        });
        this.setState({
          designations: designations,
        });
      });
    } else if (
      !this.props.replyType &&
      communicationType === CommunicationTypes.ArrivalRequest
    ) {
      MessageService.getArrivalRecipients().then((x) => {
        const designations: IUser[] = [];
        x.forEach((y) => {
          if (designations.filter((z) => z.id === y.id).length === 0) {
            designations.push(y);
          }
        });
        this.setState({
          designations: designations,
        });
      });
    } else if (
      !this.props.replyType &&
      communicationType === CommunicationTypes.TransportArrivalRequest
    ) {
      MessageService.getTransportRecipients().then((x) => {
        const designations: IUser[] = [];
        x.forEach((y) => {
          if (designations.filter((z) => z.id === y.id).length === 0) {
            designations.push(y);
          }
        });
        this.setState({
          designations: designations,
        });
      });
    } else if (
      !this.props.replyType &&
      (communicationType === CommunicationTypes.DepartureRequest ||
        communicationType === CommunicationTypes.ChangeDepartureRequest)
    ) {
      MessageService.getDepartureRecipients().then((x) => {
        const designations: IUser[] = [];
        x.forEach((y) => {
          if (designations.filter((z) => z.id === y.id).length === 0) {
            designations.push(y);
          }
        });
        this.setState({
          designations: designations,
        });
      });
    } else {
      this.setState({
        designations: [],
      });
    }

    const inspectionRequest: INewInspectionRequest | undefined =
      communicationType === CommunicationTypes.InspectionRequest
        ? {
            itemId: 0,
            addresses: [],
            enterpriseId: objectId(this.props.enterprise),
            comments: "",
            takeForeignerWorkersOutOfFerme: false,
            sendReportToCEACenter: false,
          }
        : undefined;
    const transportArrivalRequest: INewTransportArrivalRequest | undefined =
      communicationType === CommunicationTypes.TransportArrivalRequest
        ? {
            key: "0",
            id: this.props.inputMessage?.id ?? 0,
            enterpriseId: objectId(this.props.enterprise),
            isCustom: false,
            itemId: this.props.inputMessage?.id ?? 0,
            participants: [],
            errors: [],
          }
        : undefined;

    const arrivalRequest: INewArrivalRequest | undefined =
      communicationType === CommunicationTypes.ArrivalRequest
        ? {
            preauth: false,
            landTransportIncluded: false,
            participants: [],
          }
        : undefined;

    const participantTransfertRequest:
      | INewParticipantTransfertRequest
      | undefined =
      communicationType === CommunicationTypes.EmployerTransfert
        ? {
            key: "0",
            id: this.props.inputMessage?.id ?? 0,
            enterpriseId: objectId(this.props.enterprise),
            itemId: this.props.inputMessage?.id ?? 0,
            participants: [],
          }
        : undefined;

    this.setState({
      communicationType: communicationType,
      arrivalRequest: arrivalRequest,
      departureRequest: departureRequest,
      inspectionRequest: inspectionRequest,
      transportArrivalRequest: transportArrivalRequest,
      participantTransfertRequest: participantTransfertRequest,
      // onmessage: communicationType === CommunicationTypes.Standard,
    });
  };

  private validate = (
    currentRecipients?: IUser[]
  ): [any, INewMessageModel, any] => {
    const session = AuthService.getUserInfo();
    if (session === null) throw new Error("");
    const { designations, recipients, attachments, message, subject } =
      this.state;
    const to = currentRecipients ?? recipients ?? [];
    const newObj: INewMessageModel = {
      type:
        this.props.replyType && this.props.inputMessage
          ? this.props.inputMessage.type.id
          : parseInt(this.state.communicationType),
      id: this.props.replyType ? 0 : this.props.inputMessage?.id ?? 0,
      itemId: this.props.replyType ? 0 : this.props.inputMessage?.itemId ?? 0,
      enterpriseId: objectId(this.props.enterprise),
      attachments: attachments.map((x) => {
        if (x.data === undefined) x.data = null;
        return x;
      }),
      recipients: to.map((x) => x.key),
      designations: designations.map((x) => x.key),
      subject: subject,
      message: message,
    };
    let errors: string[] | undefined;
    switch (this.state.communicationType) {
      case CommunicationTypes.Standard:
        break;
      case CommunicationTypes.DepartureRequest:
        errors = this.departureRef.current?.validate();
        break;
      case CommunicationTypes.ArrivalRequest:
        errors = this.arrivalRef.current?.validate();
        break;
      case CommunicationTypes.EmployerTransfert:
        errors = this.participantTransferRef.current?.validate();
        break;
      case CommunicationTypes.TransportArrivalRequest:
        errors = this.transportArrivalRef.current?.validate();
        break;
      case CommunicationTypes.ChangeDepartureRequest:
        errors = this.departureRef.current?.validate();
        break;
      case CommunicationTypes.InspectionRequest:
        errors = this.inspectionRef.current?.validate();
        break;
    }
    return [
      validate(NewMessageModelSchema(newObj.designations.length > 0), newObj),
      newObj,
      errors,
    ];
  };

  private onRenderAttachment = (
    item: IAttachmentModel,
    subComponentStyles?: INewMessageControlSubComponentStyles
  ): JSX.Element => {
    return (
      <div
        key={item.id}
        style={{
          float: "left",
          padding: "4px",
          border: `1px solid ${this.props.theme.palette.neutralDark}`,
          maxWidth: "255px",
        }}
      >
        <Stack grow={false} horizontal tokens={{ childrenGap: 10 }}>
          <TooltipHost content={item.name}>
            <Icon
              iconName={DocumentService.getIconFromExtension(item.name)}
              style={{ fontSize: "36px" }}
            />
          </TooltipHost>
          <StackItem>
            <Stack tokens={{ childrenGap: 4 }}>
              <TooltipHost content={item.name}>
                <Text block nowrap styles={{ root: { maxWidth: "160px" } }}>
                  {item.name}
                </Text>
              </TooltipHost>
              <TooltipHost content={item.name}>
                <Text variant={"small"}>{item.size}</Text>
              </TooltipHost>
            </Stack>
          </StackItem>
          <StackItem align={"baseline"}>
            <Stack>
              <div style={{ height: "5px" }}></div>
              <TooltipHost content={item.name}>
                <IconButton
                  onClick={() => {
                    const { attachments } = this.state;
                    this.setState({
                      attachments: attachments.filter((x) => x.id !== item.id),
                    });
                  }}
                  iconProps={{ iconName: "Delete" }}
                  styles={subComponentStyles?.iconStyles}
                />
              </TooltipHost>
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  };

  private fileUploadRef: HTMLInputElement | null = null;

  private getBase64 = (
    file: File,
    cb: (item: string | ArrayBuffer | null) => void
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  private handleFileUpload = (
    _ev: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (this.fileUploadRef) {
      const { files } = this.fileUploadRef;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const item = files.item(i);
          if (item) {
            const { attachments } = this.state;
            this.getBase64(item, (x: string | ArrayBuffer | null) => {
              attachments.push({
                id: -(attachments.length + 1),
                name: item.name,
                type: "application/pdf",
                size: `${(item.size / 1024).toFixed(0)} kb`,
                data: x,
              });
              this.setState({ attachments: attachments });
              if (this.fileUploadRef) {
                this.fileUploadRef.value = "";
              }
            });
          }
        }
      }
    }
  };

  private onSave = () => {
    const { communicationType } = this.state;
    const [e1, payload, r1] = this.validate();
    if (e1 == null || r1 == null) {
      /* lint */
    }
    const errors: string[] = [];
    const requestErrors: string[] = [];
    if (Object.keys(errors).length === 0) {
      if (communicationType === CommunicationTypes.Standard) {
        MessageService.save(payload)
          .then((x) => {
            this.setState({
              sending: false,
              saving: false,
              errorMessage: undefined,
              errors: errors,
            });
            this.props.onDismiss(true, true, x.id);
          })
          .catch((_x) => {
            this.setState({
              sending: false,
              saving: false,
              errorMessage: i18n.t("messages:savedrafterror"),
              errors: errors,
            });
          });
      } else {
        const res: boolean = this.onAssistedSend(
          payload,
          communicationType,
          true,
          requestErrors
        );
        this.setState({
          sending: false,
          saving: res,
          errors: [],
        });
      }
    } else {
      this.setState({
        errors: errors,
      });
    }
  };

  private onSend = (): void => {
    const { communicationType } = this.state;
    const [errors, payload, requestErrors] = this.validate();
    if (Object.keys(errors).length === 0) {
      if (communicationType === CommunicationTypes.Standard) {
        MessageService.send(payload)
          .then((_x) => {
            this.setState({
              sending: false,
              saving: false,
              errors: errors,
              errorMessage: undefined,
            });
            this.props.onDismiss(true, false);
          })
          .catch((_x) => {
            this.setState({
              sending: false,
              saving: false,
              errorMessage: i18n.t("messages:senderror"),
              errors: errors,
            });
          });

        this.setState({
          sending: true,
          saving: false,
          errors: [],
        });
      } else {
        // Submit assisted item
        const res: boolean = this.onAssistedSend(
          payload,
          communicationType,
          false,
          requestErrors
        );

        this.setState({
          sending: res,
          saving: false,
          errors: [],
        });
      }
    } else {
      this.setState({
        errors: errors,
      });
    }
  };

  private haveErrors = (
    errors: string[],
    sub: string[] | undefined,
    isSave: boolean
  ): boolean => {
    if (isSave) return false;
    if (Object.keys(errors).length === 0) {
      if (sub) {
        if (Object.keys(sub).length === 0) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  private haveParticipantErrors = (obj: any, isSave: boolean): boolean => {
    if (isSave) return false;
    const items = obj.filter((x: any) => x.selected && x.errors.length === 0);
    return items.length > 0;
  };

  private onAssistedSend = (
    message: INewMessageModel,
    communicationType: string,
    isSave: boolean,
    errors: string[] | undefined
  ): boolean => {
    let res: boolean = false;
    switch (communicationType) {
      case CommunicationTypes.DepartureRequest:
        res =
          (this.state.departureRequest &&
            !this.haveErrors(
              this.state.departureRequestErrors,
              errors,
              isSave
            ) &&
            !this.haveParticipantErrors(
              this.state.departureRequest.participants,
              isSave
            )) ??
          false;
        this.state.departureRequest &&
          res &&
          DepartureRequestService.send(
            objectId(this.props.enterprise),
            message,
            {
              ...this.state.departureRequest,
              participants: this.state.departureRequest.participants.filter(
                (x) => x.selected
              ),
            },
            isSave
          )
            .then((x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave, x.id);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;
      case CommunicationTypes.ArrivalRequest:
        res =
          (this.state.arrivalRequest &&
            !this.haveErrors(this.state.arrivalRequestErrors, errors, isSave) &&
            !this.haveParticipantErrors(
              this.state.arrivalRequest.participants,
              isSave
            )) ??
          false;
        this.state.arrivalRequest &&
          res &&
          ArrivalRequestService.send(
            objectId(this.props.enterprise),
            message,
            {
              ...this.state.arrivalRequest,
              participants: this.state.arrivalRequest.participants.filter(
                (x) => x.selected
              ),
            },
            isSave
          )
            .then((x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave, x.id);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;

      case CommunicationTypes.EmployerTransfert:
        res =
          (this.state.participantTransfertRequest &&
            !this.haveErrors(
              this.state.participantTransfertRequestErrors,
              errors,
              isSave
            ) &&
            !this.haveParticipantErrors(
              this.state.participantTransfertRequest.participants,
              isSave
            )) ??
          false;
        this.state.participantTransfertRequest &&
          res &&
          ParticipantTransfertService.send(
            objectId(this.props.enterprise),
            message,
            {
              ...this.state.participantTransfertRequest,
              participants:
                this.state.participantTransfertRequest.participants.filter(
                  (x) => x.selected
                ),
            },
            isSave
          )
            .then((x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave, x.id);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;
      case CommunicationTypes.TransportArrivalRequest:
        res =
          (this.state.transportArrivalRequest &&
            !this.haveErrors(
              this.state.transportArrivalRequestErrors,
              errors,
              isSave
            ) &&
            !this.haveParticipantErrors(
              this.state.transportArrivalRequest.participants,
              isSave
            )) ??
          false;
        this.state.transportArrivalRequest &&
          res &&
          TransportArrivalRequestService.send(
            objectId(this.props.enterprise),
            message,
            {
              ...this.state.transportArrivalRequest,
              participants:
                this.state.transportArrivalRequest.participants.filter(
                  (x) => x.selected
                ),
            },
            isSave
          )
            .then((x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave, x.id);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;
      case CommunicationTypes.ChangeDepartureRequest:
        res =
          (this.state.departureRequest &&
            !this.haveErrors(
              this.state.departureRequestErrors,
              errors,
              isSave
            ) &&
            !this.haveParticipantErrors(
              this.state.departureRequest.participants,
              isSave
            )) ??
          false;
        this.state.departureRequest &&
          res &&
          DepartureRequestService.send(
            objectId(this.props.enterprise),
            message,
            {
              ...this.state.departureRequest,
              participants: this.state.departureRequest.participants.filter(
                (x) => x.selected
              ),
            },
            isSave
          )
            .then((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;
      case CommunicationTypes.InspectionRequest:
        res =
          (this.state.inspectionRequest &&
            !this.haveErrors(
              this.state.inspectionRequestErrors,
              errors,
              isSave
            )) ??
          false;
        this.state.inspectionRequest &&
          res &&
          InspectionRequestService.send(
            objectId(this.props.enterprise),
            message,
            this.state.inspectionRequest,
            isSave
          )
            .then((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errors: [],
                errorMessage: undefined,
              });
              this.props.onDismiss(true, isSave);
            })
            .catch((_x) => {
              this.setState({
                sending: false,
                saving: false,
                errorMessage: i18n.t("messages:senderror"),
              });
            });
        break;
    }
    return res;
  };

  componentDidUpdate() {
    if (this.state.sendtransit) {
      this.setState({
        sendtransit: false,
      });
      this.onSend();
    }
  }

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames, subComponentStyles] = getNewMessageControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const { sending, saving, confirming, errors, editorState } = this.state;
    return sending || saving ? (
      <div style={{ marginTop: mobile ? "40px" : "70px" }}>
        <ProgressPanel
          {...this.props}
          message={
            sending
              ? i18n.t("messages:send:pleasewait")
              : i18n.t("messages:savedraft:pleasewait")
          }
        />
      </div>
    ) : (
      <div className={classNames.root}>
        <input
          ref={(ref) => (this.fileUploadRef = ref)}
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={this.handleFileUpload}
        />
        <NewMessageCommandBar
          {...this.props}
          styles={undefined}
          confirming={confirming}
          canSave={true}
          onDismiss={this.props.onDismiss}
          onSave={this.onSave}
          onSend={this.onSend}
          onAttach={() => {
            if (!_this.fileUploadRef) {
              console.log("no file ref");
            }
            _this.fileUploadRef?.click();
          }}
        />
        <div>
          {this.state.errorMessage !== undefined ? (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={() => {
                this.setState({
                  errorMessage: undefined,
                });
              }}
              dismissButtonAriaLabel={i18n.t("global:close")}
            >
              {this.state.errorMessage}
            </MessageBar>
          ) : (
            <div></div>
          )}
          {this.state.designations.length > 0 && (
            <div>
              <Label>{i18n.t("messages:designations")}</Label>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                {this.state.communicationType ===
                  CommunicationTypes.InspectionRequest ||
                this.state.communicationType === CommunicationTypes.Standard ? (
                  <>
                    {this.state.designations.map((x) => {
                      return (
                        <Persona
                          initialsColor={"rgb(122, 117, 116)"}
                          size={PersonaSize.size24}
                          text={x.name}
                          key={x.key}
                          styles={{
                            primaryText: {
                              fontWeight: 600,
                            },
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Persona
                    initialsColor={"rgb(122, 117, 116)"}
                    size={PersonaSize.size24}
                    text={i18n.t("messages:designations:dispatcher")}
                    key="dispatcher"
                    styles={{
                      primaryText: {
                        fontWeight: 600,
                      },
                    }}
                  />
                )}
              </Stack>
            </div>
          )}
          <div>
            <Label required={this.state.designations.length === 0}>
              {i18n.t(
                this.state.designations.length === 0
                  ? "messages:recipients"
                  : "messages:recipients:extra"
              )}
            </Label>
            <UsersPicker
              {...this.props}
              styles={undefined}
              errorMessage={errors.recipients}
              onSelectionChanged={(selectedItems?: IPersonaProps[]) => {
                const recipients = selectedItems?.map((x) => {
                  return {
                    id: x?.itemID ?? "",
                    key: x?.itemID ?? "",
                    name: x?.text ?? "",
                    email: x?.optionalText ?? "",
                    phone: "",
                    role: "",
                  };
                });

                this.setState({
                  recipients: recipients ?? [],
                });
              }}
              selection={this.state.recipients.map(this.userToPersona)}
              peopleList={this.state.peopleList}
              mostRecentlyUsed={this.state.peopleList}
            />
          </div>
          <div>
            <Label required>{i18n.t("messages:subject")}</Label>
            <TextField
              minLength={3}
              maxLength={250}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                this.setState({
                  subject: newValue ?? "",
                });
              }}
              value={this.state.subject}
              validateOnLoad={false}
              required
              errorMessage={errors.subject}
              readOnly={this.state.communicationType === CommunicationTypes.InspectionRequest}
            />
          </div>
          {this.state.attachments.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              {this.state.attachments.map((x) => {
                return this.onRenderAttachment(x, subComponentStyles);
              })}
              <div className="clear-both"></div>
              <div style={{ height: "5px" }}></div>
            </div>
          )}
          {!this.props.replyType && (
            <CommunicationTypeSelector
              {...this.props}
              styles={undefined}
              disabled={
                this.props.inputMessage !== undefined &&
                this.props.inputMessage.id > 0
              }
              communicationType={this.state.communicationType}
              onChange={this.onCommunicationTypeChanged}
            />
          )}
          {this.state.communicationType === CommunicationTypes.Standard ? (
            <MessageEditor
              {...this.props}
              styles={undefined}
              required={true}
              editorState={editorState}
              errorMessage={errors.message}
              onChange={(editorState: any, value: string) =>
                this.setState({
                  editorState: editorState as any,
                  message: value,
                })
              }
              label={"Message"}
            />
          ) : (
            <AssistedMessageEditionContainer
              {...this.props}
              styles={undefined}
              assitanceLabel={i18n.t(
                this.commTypeToLabel(this.state.communicationType)
              )}
              editorState={editorState}
              errorMessage={errors.message}
              onChange={(editorState: EditorState, value: string) =>
                this.setState({ editorState: editorState, message: value })
              }
              onmessage={this.state.onmessage}
              onTabChanged={(onmessage: boolean) =>
                this.setState({ onmessage: onmessage })
              }
            >
              {
                {
                  [CommunicationTypes.DepartureRequest]: (
                    <>
                      {this.state.departureRequest && (
                        <DepartureAssistedEditor
                          {...this.props}
                          styles={undefined}
                          request={this.state.departureRequest}
                          errors={this.state.departureRequestErrors}
                          flights={this.state.departureRequestFlights}
                          onUpdate={(
                            item: INewDepartureRequest,
                            fetched?: boolean,
                            errors?: string[]
                          ) => {
                            this.setState({
                              departureRequest: item,
                              departureRequestParticipantsFetched:
                                fetched ??
                                this.state.departureRequestParticipantsFetched,
                              departureRequestErrors:
                                errors ?? this.state.departureRequestErrors,
                            });
                          }}
                          onFlightsUpdate={(
                            item: INewDepartureRequest,
                            flights: IDepartureRequestFlight[],
                            fetched?: boolean
                          ) => {
                            this.setState({
                              departureRequest: item,
                              departureRequestParticipantsFetched:
                                fetched ??
                                this.state.departureRequestParticipantsFetched,
                              departureRequestFlights: flights,
                              departureRequestFlightsFetched: true,
                            });
                          }}
                          onDataModelUpdate={(
                            model: IDepartureRequestDataModel,
                            request?: INewDepartureRequest
                          ) => {
                            this.setState({
                              departureRequestModel: model,
                              departureRequest:
                                request ?? this.state.departureRequest,
                            });
                          }}
                          participantsFetched={
                            this.state.departureRequestParticipantsFetched
                          }
                          model={this.state.departureRequestModel}
                          componentRef={this.departureRef}
                          changing={false}
                        />
                      )}
                    </>
                  ),
                  [CommunicationTypes.ChangeDepartureRequest]: (
                    <>
                      {this.state.departureRequest && (
                        <DepartureAssistedEditor
                          {...this.props}
                          {...this.state}
                          errors={this.state.departureRequestErrors}
                          componentRef={this.departureRef}
                          styles={undefined}
                          request={this.state.departureRequest}
                          flights={this.state.departureRequestFlights}
                          onUpdate={(
                            item: INewDepartureRequest,
                            fetched?: boolean,
                            errors?: string[]
                          ) => {
                            this.setState({
                              departureRequest: item,
                              departureRequestParticipantsFetched:
                                fetched ??
                                this.state.departureRequestParticipantsFetched,
                              departureRequestErrors:
                                errors ?? this.state.departureRequestErrors,
                            });
                          }}
                          onFlightsUpdate={(
                            item: INewDepartureRequest,
                            flights: IDepartureRequestFlight[],
                            fetched?: boolean
                          ) => {
                            this.setState({
                              departureRequest: item,
                              departureRequestParticipantsFetched:
                                fetched ??
                                this.state.departureRequestParticipantsFetched,
                              departureRequestFlights: flights,
                              departureRequestFlightsFetched: true,
                            });
                          }}
                          onDataModelUpdate={(
                            model: IDepartureRequestDataModel,
                            request?: INewDepartureRequest
                          ) => {
                            this.setState({
                              departureRequestModel: model,
                              departureRequest:
                                request ?? this.state.departureRequest,
                            });
                          }}
                          participantsFetched={
                            this.state.departureRequestParticipantsFetched
                          }
                          model={this.state.departureRequestModel}
                          changing={true}
                        />
                      )}
                    </>
                  ),
                  [CommunicationTypes.EmployerTransfert]: (
                    <>
                      {this.state.participantTransfertRequest && (
                        <ParticipantTransfertAssistedEditor
                          {...this.props}
                          styles={undefined}
                          participantsFetched={
                            this.state
                              .participantTransfertRequestParticipantFetched
                          }
                          model={this.state.participantTransfertRequestModel}
                          componentRef={this.participantTransferRef}
                          request={this.state.participantTransfertRequest}
                          errors={this.state.participantTransfertRequestErrors}
                          onDataModelUpdate={(
                            model: IParticipantTransfertRequestDataModel,
                            item?: INewParticipantTransfertRequest,
                            fetched?: boolean
                          ) => {
                            this.setState({
                              participantTransfertRequestModel: model,
                              participantTransfertRequest:
                                item || this.state.participantTransfertRequest,
                              participantTransfertRequestParticipantFetched:
                                fetched ??
                                this.state
                                  .participantTransfertRequestParticipantFetched,
                            });
                          }}
                          onUpdate={(
                            item: INewParticipantTransfertRequest,
                            fetched?: boolean,
                            errors?: string[]
                          ) => {
                            this.setState({
                              participantTransfertRequest: item,
                              participantTransfertRequestParticipantFetched:
                                fetched ??
                                this.state
                                  .participantTransfertRequestParticipantFetched,
                              participantTransfertRequestErrors:
                                errors ??
                                this.state.participantTransfertRequestErrors,
                            });
                          }}
                        />
                      )}
                    </>
                  ),
                  [CommunicationTypes.ArrivalRequest]: (
                    <>
                      {this.state.arrivalRequest && (
                        <ArrivalAssistedEditor
                          {...this.props}
                          {...this.state}
                          styles={undefined}
                          errors={this.state.arrivalRequestErrors}
                          componentRef={this.arrivalRef}
                          request={this.state.arrivalRequest}
                          flights={this.state.arrivalRequestFlights}
                          onUpdate={(
                            item: INewArrivalRequest,
                            fetched?: boolean,
                            errors?: string[]
                          ) => {
                            this.setState({
                              arrivalRequest: item,
                              arrivalRequestParticipantsFetched:
                                fetched ??
                                this.state.arrivalRequestParticipantsFetched,
                              arrivalRequestErrors:
                                errors ?? this.state.arrivalRequestErrors,
                            });
                          }}
                          onFlightsUpdate={(
                            item: INewArrivalRequest,
                            flights: IArrivalRequestFlight[],
                            fetched?: boolean
                          ) => {
                            this.setState({
                              arrivalRequest: item,
                              arrivalRequestParticipantsFetched:
                                fetched ??
                                this.state.arrivalRequestParticipantsFetched,
                              arrivalRequestFlights: flights,
                              arrivalRequestFlightsFetched: true,
                            });
                          }}
                          onDataModelUpdate={(
                            model: IArrivalRequestDataModel,
                            request?: INewArrivalRequest
                          ) => {
                            this.setState({
                              arrivalRequestModel: model,
                              arrivalRequest:
                                request ?? this.state.arrivalRequest,
                            });
                          }}
                          participantsFetched={
                            this.state.arrivalRequestParticipantsFetched
                          }
                          model={this.state.arrivalRequestModel}
                          changing={false}
                        />
                      )}
                    </>
                  ),
                  [CommunicationTypes.TransportArrivalRequest]: (
                    <>
                      {this.state.transportArrivalRequest && (
                        <TransportArrivalAssistedRequestEditor
                          {...this.props}
                          {...this.state}
                          styles={undefined}
                          errors={this.state.transportArrivalRequestErrors}
                          model={this.state.transportArrivalRequestModel}
                          componentRef={this.transportArrivalRef}
                          request={this.state.transportArrivalRequest}
                          onDataModelUpdate={(
                            model: ITransportArrivalRequestDataModel,
                            request?: INewTransportArrivalRequest
                          ) => {
                            this.setState({
                              transportArrivalRequestModel: model,
                              transportArrivalRequest:
                                request ?? this.state.transportArrivalRequest,
                            });
                          }}
                          onUpdate={(
                            item: INewTransportArrivalRequest,
                            fetched?: boolean,
                            errors?: string[]
                          ) => {
                            this.setState({
                              transportArrivalRequest: item,
                              transportArrivalRequestParticipantsFetched:
                                fetched ??
                                this.state
                                  .transportArrivalRequestParticipantsFetched,
                              transportArrivalRequestErrors:
                                errors ??
                                this.state.transportArrivalRequestErrors,
                            });
                          }}
                        />
                      )}
                    </>
                  ),
                  [CommunicationTypes.InspectionRequest]: (
                    <>
                      {this.isConfirmingInspection() && (
                        <GridViewDialog
                          {...this.props}
                          yes={
                            this.state.inspectionRequest?.warned === undefined
                              ? i18n.t("global:ok")
                              : i18n.t("messages:send")
                          }
                          no={i18n.t("global:cancel")}
                          yesDisabled={
                            !this.state.inspectionRequest ||
                            (this.state.inspectionRequest.warned !==
                              undefined &&
                              this.state.inspectionRequest.confirmed ===
                                undefined)
                          }
                          isConfirming={true}
                          confirmTitle={i18n.t(
                            "messages:assisted:inspection:confirmation:title"
                          )}
                          confirmMessage={
                            this.state.inspectionRequest?.warned ===
                            undefined ? (
                              <Stack tokens={{ childrenGap: 10 }}>
                                <Text
                                  variant="mediumPlus"
                                  styles={{ root: { fontWeight: 600 } }}
                                >
                                  {i18n.t(
                                    "messages:assisted:inspection:confirmation"
                                  )}
                                </Text>
                                <ul>
                                  <li>
                                    {i18n.t(
                                      "messages:assisted:inspection:confirmation:item1"
                                    )}
                                  </li>
                                  <li>
                                    {i18n.t(
                                      "messages:assisted:inspection:confirmation:item2"
                                    )}
                                  </li>
                                  <li>
                                    {i18n.t(
                                      "messages:assisted:inspection:confirmation:item3"
                                    )}
                                  </li>
                                  <li>
                                    {i18n.t(
                                      "messages:assisted:inspection:confirmation:item4"
                                    )}
                                  </li>
                                  <li>
                                    {i18n.t(
                                      "messages:assisted:inspection:confirmation:item5"
                                    )}
                                  </li>
                                </ul>
                                <br />
                                <Text
                                  variant="mediumPlus"
                                  styles={{
                                    root: {
                                      fontWeight: 600,
                                      color: this.props.theme.palette.redDark,
                                    },
                                  }}
                                >
                                  {i18n.t(
                                    "messages:assisted:inspection:confirmation:reminder"
                                  )}
                                </Text>
                                <Text
                                  variant="mediumPlus"
                                  styles={{ root: { fontWeight: 600 } }}
                                >
                                  {i18n.t(
                                    "messages:assisted:inspection:confirmation:footer"
                                  )}
                                </Text>
                              </Stack>
                            ) : (
                              <Stack tokens={{ childrenGap: 10 }}>
                                <Text
                                  styles={{
                                    root: { padding: "5px", fontWeight: 600 },
                                  }}
                                >
                                  {i18n.t(
                                    "messages:assisted:inspection:confirmation:header"
                                  )}
                                </Text>
                                <Text styles={{ root: { padding: "5px" } }}>
                                  {this.state.inspectionRequest.addresses
                                    .length > 1
                                    ? i18n.t(
                                        "messages:assisted:inspection:confirmation:description:plural"
                                      )
                                    : i18n.t(
                                        "messages:assisted:inspection:confirmation:description"
                                      )}
                                </Text>
                                {this.state.inspectionRequest ? (
                                  this.state.inspectionRequest.addresses.map(
                                    (address, index) => (
                                      <div
                                        key={index.toString()}
                                        style={{
                                          marginLeft: "5px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <div>{address.address.street}</div>
                                        <div>{`${address.address.city} ${
                                          address.address.city &&
                                          address.address.city.length > 0 &&
                                          address.address.state &&
                                          address.address.state.length > 0
                                            ? "("
                                            : ""
                                        }${address.address.state}${
                                          address.address.city &&
                                          address.address.city.length > 0 &&
                                          address.address.state &&
                                          address.address.state.length > 0
                                            ? ")"
                                            : ""
                                        }`}</div>
                                        <div>{address.address.postalcode}</div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                                <Text styles={{ root: { padding: "5px" } }}>
                                  {i18n.t(
                                    "messages:assisted:inspection:confirmation:instructions"
                                  )}
                                </Text>
                                <Checkbox
                                  label={i18n.t(
                                    "messages:assisted:inspection:checkbox"
                                  )}
                                  styles={{ root: { marginLeft: "5px" } }}
                                  checked={
                                    this.state.inspectionRequest &&
                                    this.state.inspectionRequest.confirmed !==
                                      undefined
                                  }
                                  onChange={(
                                    _ev?: React.FormEvent<
                                      HTMLElement | HTMLInputElement
                                    >,
                                    checked?: boolean
                                  ) => {
                                    if (this.state.inspectionRequest) {
                                      this.setState({
                                        inspectionRequest: {
                                          ...this.state.inspectionRequest,
                                          confirmed:
                                            checked ?? false
                                              ? "confirmed"
                                              : undefined,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </Stack>
                            )
                          }
                          onDismiss={(result: boolean) => {
                            if (
                              result &&
                              this.state.inspectionRequest &&
                              this.state.inspectionRequest.warned === undefined
                            ) {
                              this.setState({
                                inspectionRequest: {
                                  ...this.state.inspectionRequest,
                                  warned: "warned",
                                },
                              });
                            } else if (
                              result &&
                              this.state.inspectionRequest &&
                              this.state.inspectionRequest.confirmed !==
                                undefined
                            ) {
                              this.setState({
                                sendtransit: true,
                                inspectionRequestErrors: [],
                              });
                            } else if (this.state.inspectionRequest) {
                              this.setState({
                                inspectionRequest: {
                                  ...this.state.inspectionRequest,
                                  warned: undefined,
                                  confirmed: undefined,
                                },
                                inspectionRequestErrors: [],
                              });
                            } else {
                              this.setState({
                                inspectionRequestErrors: [],
                              });
                            }
                          }}
                        />
                      )}
                      <InspectionRequestEditor
                        {...this.props}
                        {...this.state}
                        componentRef={this.inspectionRef}
                        styles={undefined}
                        errors={this.state.inspectionRequestErrors}
                        model={this.state.inspectionRequestModel}
                        request={this.state.inspectionRequest}
                        onUpdate={(
                          item: INewInspectionRequest,
                          _fetched?: boolean,
                          errors?: string[]
                        ) => {
                          this.setState({
                            inspectionRequest: item,
                            inspectionRequestErrors:
                              errors ?? this.state.inspectionRequestErrors,
                          });
                        }}
                        onDataModelUpdate={(
                          model: IInspectionRequestDataModel,
                          request?: INewInspectionRequest
                        ) => {
                          if (request) {
                            this.setState({
                              inspectionRequest: request,
                              inspectionRequestModel: {
                                ...model,
                                editorState: request.comments
                                  ? EditorState.createWithContent(
                                      RichTextService.getContentState(
                                        request.comments
                                      ) as any
                                    )
                                  : EditorState.createEmpty(),
                                comments: request.comments,
                              },
                            });
                          } else {
                            this.setState({
                              inspectionRequestModel: {
                                ...model,
                                editorState: EditorState.createEmpty(),
                                comments: "",
                              },
                            });
                          }
                        }}
                        onCommentsChanged={(
                          editorState: EditorState,
                          comments: string
                        ) => {
                          const { inspectionRequestModel, inspectionRequest } =
                            this.state;
                          inspectionRequestModel &&
                            inspectionRequest &&
                            this.setState({
                              inspectionRequest: {
                                ...inspectionRequest,
                                comments: comments,
                              },
                              inspectionRequestModel: {
                                ...inspectionRequestModel,
                                editorState: editorState,
                              },
                            });
                        }}
                      />
                    </>
                  ),
                }[this.state.communicationType]
              }
            </AssistedMessageEditionContainer>
          )}
        </div>
      </div>
    );
  }
}
