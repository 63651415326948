import {
  Icon,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  VerticalDivider,
} from "@fluentui/react";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { getFlightOfferDetailsClassNames } from "./FlightOfferDetails.styles";
import {
  IFlightOfferDetailsProps,
  IFlightOfferDetailsState,
} from "./FlightOfferDetails.types";

export class FlightOfferDetailsComponent extends React.Component<
  IFlightOfferDetailsProps,
  IFlightOfferDetailsState
> {
  private adjusted = (adj: number | undefined): string => {
    return adj ? `+${adj}` : "";
  };

  render(): JSX.Element {
    const {
      styles,
      mobile,
      offer,
      offerIndex,
      serviceIndex,
      booking,
      flexible,
      onOfferSelected,
    } = this.props;
    const [classNames] = getFlightOfferDetailsClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const features: boolean = false;
    return (
      <div className={classNames.root}>
        <Stack
          tokens={{ childrenGap: 10 }}
          styles={{
            root: {
              borderTop: "1px solid " + this.props.theme.palette.neutralLighter,
            },
          }}
        >
          {offer.schedules.map((x) => {
            return (
              <React.Fragment key={x.id}>
                {x.layover && (
                  <MessageBar
                    messageBarType={MessageBarType.info}
                    messageBarIconProps={{ iconName: "BufferTimeBefore" }}
                  >
                    <Text variant={"large"}>{`${x.layover} ${i18n.t(
                      "transports:flights:airport:layover"
                    )} - ${x.from}`}</Text>
                  </MessageBar>
                )}
                <Stack
                  key={x.id}
                  horizontal
                  tokens={{ childrenGap: 10, padding: "2px" }}
                  grow
                >
                  <StackItem align={"center"} verticalFill>
                    {x.operating === "" ||
                      (x.operating === "multiple" && (
                        <Icon
                          iconName={"AirplaneSolid"}
                          style={{ fontSize: "48px" }}
                        />
                      ))}
                    {x.operating !== "" && x.operating !== "multiple" && (
                      <img
                        src={TransportFlightSearchService.getCarrierImage(
                          x.operating
                        )}
                        style={{
                          marginLeft: "5px",
                          width: "48px",
                          height: "48px",
                        }}
                      />
                    )}
                  </StackItem>
                  <Stack tokens={{ childrenGap: 5, padding: "6px" }} grow>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 10 }}
                      styles={
                        mobile
                          ? { root: { marginLeft: "0px!important" } }
                          : undefined
                      }
                    >
                      <StackItem verticalFill>
                        <VerticalDivider
                          styles={{
                            divider: {
                              minHeight: "145px",
                              marginTop: "5px",
                            },
                          }}
                        />
                      </StackItem>
                      <Stack
                        tokens={{
                          childrenGap: mobile ? "1px" : 5,
                          padding: mobile ? "0 6px" : "8px",
                        }}
                        grow
                      >
                        <LabelField
                          {...this.props}
                          styles={{
                            description: {
                              fontSize: mobile ? undefined : "16px",
                            },
                            value: {
                              fontSize: mobile ? undefined : "16px",
                            },
                          }}
                          label={TransportFlightSearchService.getAirportName(
                            x.from
                          )}
                          content={`${x.departureDate} ${x.departure.substr(0, 5)}${this.adjusted(
                            x.departureAjustment
                          )}`}
                        />
                        <Text
                          style={{
                            fontWeight: 600,
                            fontSize: mobile ? undefined : "16px",
                            color: "lightslategray",
                            marginTop: "0px!important",
                            paddingTop: mobile ? "1px" : "1px",
                            paddingBottom: "1px",
                          }}
                        >
                          {x.elapsedtime}
                        </Text>
                        <LabelField
                          {...this.props}
                          styles={{
                            description: {
                              fontSize: mobile ? undefined : "16px",
                            },
                            value: {
                              fontSize: mobile ? undefined : "16px",
                            },
                          }}
                          label={TransportFlightSearchService.getAirportName(
                            x.to
                          )}
                          content={`${x.arrivalDate} ${x.arrival.substr(0, 5)}${this.adjusted(
                            x.arrivalAdjustement
                          )}`}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  {features && !mobile && (
                    <Stack>
                      <Stack
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        style={{
                          marginTop: "15px",
                          marginRight: "30px",
                          paddingTop: "10px",
                          paddingRight: "15px",
                        }}
                      >
                        <Stack tokens={{ childrenGap: 5 }}>
                          <Icon
                            iconName={"Glimmer"}
                            styles={{
                              root: {
                                color: this.props.theme.palette.black,
                              },
                            }}
                          />
                          <Icon
                            iconName={"InternetSharing"}
                            styles={{
                              root: {
                                color: this.props.theme.palette.black,
                              },
                            }}
                          />
                          <Icon
                            iconName={"LightningBolt"}
                            styles={{
                              root: {
                                color: this.props.theme.palette.black,
                              },
                            }}
                          />
                          <Icon
                            iconName={"Streaming"}
                            styles={{
                              root: {
                                color: this.props.theme.palette.black,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          tokens={{ childrenGap: 5 }}
                          horizontalAlign={"start"}
                        >
                          <Text
                            style={{
                              fontWeight: 600,
                              fontSize: mobile ? undefined : "16px",
                              color: this.props.theme.palette.neutralDark,
                            }}
                          >
                            Standard recliner seat
                          </Text>
                          <Text
                            style={{
                              fontWeight: 600,
                              fontSize: mobile ? undefined : "16px",
                              color: this.props.theme.palette.neutralDark,
                            }}
                          >
                            Wi-Fi
                          </Text>
                          <Text
                            style={{
                              fontWeight: 600,
                              fontSize: mobile ? undefined : "16px",
                              color: this.props.theme.palette.neutralDark,
                            }}
                          >
                            In-seat power outlet
                          </Text>
                          <Text
                            style={{
                              fontWeight: 600,
                              fontSize: mobile ? undefined : "16px",
                              color: this.props.theme.palette.neutralDark,
                            }}
                          >
                            Stream media to your device
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <Stack
                  horizontalAlign={mobile ? "end" : "start"}
                  styles={{
                    root: {
                      marginTop: mobile ? undefined : "0px!important",
                      marginLeft: mobile ? undefined : "64px",
                      marginRight: mobile ? "5px" : undefined,
                    },
                  }}
                  tokens={{ childrenGap: 5 }}
                >
                  <Stack
                    horizontal
                    tokens={{ childrenGap: mobile ? 10 : 15 }}
                    horizontalAlign={"end"}
                  >
                    <Text
                      nowrap
                      style={{
                        fontWeight: "normal",
                        fontSize: mobile ? undefined : "16px",
                        color: "lightslategray",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                    >
                      {TransportFlightSearchService.getCarrierName(x.operating)}
                    </Text>
                    <Text
                      nowrap
                      style={{
                        fontWeight: "normal",
                        fontSize: mobile ? undefined : "16px",
                        color: "lightslategray",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                    >
                      {x.className}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "normal",
                        fontSize: mobile ? undefined : "16px",
                        color: "lightslategray",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                    >
                      {x.equipment}
                    </Text>
                    <Text
                      nowrap
                      style={{
                        fontWeight: "normal",
                        fontSize: mobile ? undefined : "16px",
                        color: "lightslategray",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                    >
                      {x.flight}
                    </Text>
                  </Stack>
                </Stack>
              </React.Fragment>
            );
          })}

          {!booking && (
            <Stack grow styles={{ root: { width: "100%" } }}>
              <StackItem shrink align={"end"}>
                <PrimaryButton
                  styles={{
                    root: {
                      margin: "5px",
                    },
                  }}
                  text={i18n.t("transports:flights:booknow")}
                  onClick={() =>
                    onOfferSelected?.(offer, serviceIndex, offerIndex, flexible)
                  }
                />
              </StackItem>
            </Stack>
          )}
        </Stack>
      </div>
    );
  }
}
