import axios from "axios";
import { IParticipantTransportSummary } from "src/models/Requests/IParticipantTransportSummary";
import { IParticipantTransfertSummary } from "src/models/Requests/IParticipantTransfertSummary";
import { IRequestParticipantDetail } from "src/models/Requests/IRequestParticipantDetail";
import { IRequestPositionDetail } from "src/models/Requests/IRequestPositionDetail";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import { IPeriodParticipant } from "../../models/Requests/IPeriodParticipant";
import { IReportsConfirmationDatesModel } from "../../models/Requests/IReportsConfirmationDatesModel";
import { IRequestDetail } from "../../models/Requests/IRequestDetail";
import { IRequestPeriodDetail } from "../../models/Requests/IRequestPeriodDetail";
import { IRequestReference } from "../../models/Requests/IRequestReference";
import AuthService from "../AuthService";
import DocumentService from "../Documents/DocumentService";
import i18n from "../i18n";

class RequestsService {
  private _requests?: IRequestReference[] = undefined;
  private _requestsPages: number = 1;

  public init(): void {
    this._requests = undefined;
    this._requestsPages = 1;
    this.pageSize = 20;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("key", "Id")
      .replace("name", "NumeroDemande")
      .replace("agentLabel", "AgentAttritre")
      .replace("agent", "AgentAttritre")
      .replace("country", "PaysConcerne")
      .replace("status", "StatutConfirme")
      .replace("year", "Annee")
      .replace("versionTitle", "NumeroDemande")
      .replace("versionDate", "DateCreation")
      .replace("version", "NumeroVersion")
      .replace("offPTAS", "HorsPtas")
      .replace("thirdParty", "TiercePartie")
      .replace("participantCount", "NombrePoste");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString()?.length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.value !== null &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[])?.length) ||
              (x.type === "date" &&
                x.value !== undefined &&
                x.value !== null) ||
              (x.type === "null" &&
                x.value !== undefined &&
                x.value !== null &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) =>
                      x.fieldName === "country"
                        ? z.name.replace("country:", "")
                        : z.id.toString()
                    )
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async getRequestPeriod(
    enterpriseId: number,
    requestId: number,
    periodId: number,
    sortBy: string,
    desc: boolean
  ): Promise<IRequestPeriodDetail> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/requests/${requestId}/periods/${periodId}`,
      {
        sortBy: sortBy,
        descending: desc,
      },
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async exportRequestPeriod(
    enterpriseId: number,
    requestId: number,
    periodId: number,
    sortBy: string,
    desc: boolean
  ): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/requests/${requestId}/periods/${periodId}/export`,
      {
        sortBy: sortBy,
        descending: desc,
      },
      AuthService.getAuthHeaderWithBlob()
    );

    x.status < 299 && DocumentService.openBlob(x);

    return x.status < 299;
  }

  public async getRequestParticipants(
    sortBy: string,
    desc: boolean,
    enterpriseId: number,
    requestId: number,
    versionId?: number
  ): Promise<IPeriodParticipant[]> {
    const x = await axios.post(
      versionId
        ? `/api/enterprises/${enterpriseId}/requests/${requestId}/versions/${versionId}/participants`
        : `/api/enterprises/${enterpriseId}/requests/${requestId}/participants`,
      {
        sortBy,
        descending: desc,
      },
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async exportRequestParticipants(
    sortBy: string,
    desc: boolean,
    enterpriseId: number,
    requestId: number,
    versionId?: number
  ): Promise<boolean> {
    const x = await axios.post(
      versionId
        ? `/api/enterprises/${enterpriseId}/requests/${requestId}/versions/${versionId}/participants/export`
        : `/api/enterprises/${enterpriseId}/requests/${requestId}/participants/export`,
      {
        sortBy,
        descending: desc,
      },
      AuthService.getAuthHeaderWithBlob()
    );
    x.status < 299 && DocumentService.openBlob(x);

    return x.status < 299;
  }

  public async getRequestReference(
    enterpriseId: number,
    requestId: number,
    versionId?: number
  ): Promise<IRequestDetail> {
    const x = await axios.post(
      versionId
        ? `/api/enterprises/${enterpriseId}/requests/${requestId}/versions/${versionId}`
        : `/api/enterprises/${enterpriseId}/requests/${requestId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getRequestReferences(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IRequestReference[]> {
    const _this = this;
    let currentPage = this._requestsPages;
    let combine: boolean;
    if (next && _this._requests !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        `/api/enterprises/${enterpriseId}/requests`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._requests !== undefined) {
        _this._requestsPages = currentPage;
        _this._requests = _this._requests.concat(items);
      } else {
        _this._requestsPages = 1;
        _this._requests = this._requests = items;
      }
      return _this._requests ?? [];
    } catch (ex) {
      console.log("error");
      return _this._requests ?? [];
    }
  }

  public async getRequestParticipantDetail(
    enterpriseId: number,
    requestId: number,
    participantId: number
  ): Promise<IRequestParticipantDetail> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/requests/${requestId}/participants/${participantId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public print(
    type: number,
    enterpriseId: number,
    requestId: number,
    versionNumber: number,
    date: Date | undefined,
    language: string
  ): Promise<void> {
    let name: string = "info";
    switch (type) {
      case 1:
        name = "info";
        break;
      case 2:
        name = "arrivals";
        break;
      case 3:
        name = "departures";
        break;
      default:
        name = "info";
        break;
    }

    return axios
      .post(
        `/api/enterprises/${enterpriseId}/requests/${requestId}/reports/${name}`,
        {
          language: language ?? i18n.getLanguage(),
          enterpriseId: enterpriseId,
          requestId: requestId,
          date: date,
          versionId: versionNumber,
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }

  public async getDates(
    enterpriseId: number,
    requestId: number,
    versionNumber: number
  ): Promise<IReportsConfirmationDatesModel> {
    const x = await axios.post(
      `api/enterprises/${enterpriseId}/requests/${requestId}/versions/${versionNumber}/dates`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async exportRequests(
    enterpriseId: number,
    annee : string,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/requests/export/${annee}`,
      {
        sortBy: this.translateSortBy(sortBy),
        descending: desc,
        pageIndex: 0,
        pageSize: 1000,
        filters: this.transformFilters(filters),
      },
      AuthService.getAuthHeaderWithBlob()
    );
    if (x.status === 200) {
      DocumentService.openBlob(x);
      return true;
    }
    return false;
  }

  public async getPosition(
    enterpriseId: number,
    posteId: number
  ): Promise<IRequestPositionDetail | undefined> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/positions/${posteId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.status === 200 ? (x.data as any) : undefined;
  }

  public async getTransportDetail(
    enterpriseId: number,
    participantId: number
  ): Promise<IParticipantTransportSummary | undefined> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/participants/${participantId}/transport`,
      {},
      AuthService.getAuthHeader()
    );
    return x.status === 200 ? (x.data as any) : undefined;
  }

  public async getTransfertDetail(
    enterpriseId: number,
    requestId: number,
    travailleurId: number,
    date: string,
    transfertKey: string,
    arrival: boolean
  ): Promise<IParticipantTransfertSummary | undefined> {
    const transportType = arrival ? "arrivals" : "departures";
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transport-transferts/${transportType}/${requestId}`,
      {
        enterpriseId,
        requestId,
        travailleurId,
        date,
        transfertKey,
        arrival,
      },
      AuthService.getAuthHeader()
    );
    return x.status === 200 ? (x.data as any) : undefined;
  }

  public async getTransportDetailParticipation(
    enterpriseId: number,
    participationId: number,
    arrival: boolean
  ): Promise<IParticipantTransportSummary | undefined> {
    const transportType = arrival ? "arrivals" : "departures";
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transport-participation/${transportType}/${participationId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.status === 200 ? (x.data as any) : undefined;
  }
}

export default new RequestsService();
